import React from "react";
const SwitchToggle = (props) => {
  const { handleChange, checked, disabled, cssClass } = props;
  return (
    <label className={`switch ${cssClass ?? ""}`}>
      <input type="checkbox" onChange={handleChange} checked={checked} disabled={disabled} />
      <span className={`slider round ${disabled ? "disabled" : ""}`} />
    </label>
  );
};
export default SwitchToggle;
