/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Employees from "views/Employees";
import checkEligibility from "views/CheckEligibility";
import garageIcon from "../assets/img/settings-garage.png";
import employeeIcon from "../assets/img/employee-icon.png";
import activeProgramIcon from "../assets/img/active-programs.png";
import copyIcon from "../assets/img/Copy-icon.svg";
import settingIcon from "../assets/img/settings-garage.png";
import calendarIcon from "../assets/img/calendar-icon.svg";
import Provider from "views/Provider";
import EmployeeClaim from "views/EmployeeClaim";
import { t } from "stringConstants";
import LabTestTypesSetting from "views/TestTypeSetting/LabTestTypesSetting";
import Users from "views/Users";
import Companies from "views/BRMCompany/BrmCompany";

const dashboardRoutes = [
  {
    path: "/members",
    name: t("clients"),
    icon: "nc-icon nc-badge",
    src: employeeIcon,
    component: Employees,
    layout: "/admin",
  },

  {
    path: "/checkeligibility",
    name: t("checkEligibility"),
    icon: "nc-icon nc-badge",
    src: calendarIcon,
    component: checkEligibility,
    layout: "/admin",
  },
  {
    path: "/employeeclaim",
    name: t("employeeClaim"),
    icon: "nc-icon nc-badge",
    component: EmployeeClaim,
    layout: "/admin",
  },
  {
    path: "/companies",
    name: t("companies"),
    icon: "nc-icon nc-badge",
    src: activeProgramIcon,
    component: Companies,
    layout: "/admin",
  },
  {
    path: "/users",
    name: t("users"),
    icon: "nc-icon nc-badge",
    src: garageIcon,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: t("settings"),
    icon: "nc-icon nc-badge",
    src: settingIcon,
    component: LabTestTypesSetting,
    layout: "/admin",
  },
];

export default dashboardRoutes;
