import React, { useEffect, useState } from "react";
import { currencyformatter } from "constant";
import { formatDateMDY } from "utils";

const ChargeInfoRow = ({ chargeData, index }) => {
  const [charge, setCharge] = useState(chargeData || null);

  useEffect(() => {
    formatChargeListView(chargeData);
  }, [chargeData]);

  const formatChargeListView = (chargeData) => {
    const adjustedAmount = chargeData.adjustment?.reduce((prev, ad) => {
      if (
        (ad.code === "45" && ad.group == "CO") ||
        (ad.code === "253" && ad.group == "CO") ||
        (ad.code === "45" && ad.group == "PR")
      ) {
        return parseFloat(ad.amount) + prev;
      }
    }, 0);
    const deductible = chargeData.adjustment?.reduce((prev, ad) => {
      if (ad.code === "1" && ad.group == "PR") {
        return parseFloat(ad.amount) + prev;
      }
    }, 0);
    const coinsurance = chargeData.adjustment?.reduce((prev, ad) => {
      if (ad.code === "2" && ad.group == "PR") {
        return parseFloat(ad.amount) + prev;
      }
    }, 0);
    const copay = chargeData.adjustment?.reduce((prev, ad) => {
      if (ad.code === "3" && ad.group == "PR") {
        return parseFloat(ad.amount) + prev;
      }
    }, 0);

    setCharge({ ...chargeData, adjustedAmount, deductible, coinsurance, copay });
  };

  return (
    <>
      <tr key={index} className="expended-row">
        <td className="text-center p-2">{formatDateMDY(charge.from_dos)}</td>
        <td className="text-center p-2">{currencyformatter.format(charge.charge || 0)}</td>
        <td className="text-center p-2">{currencyformatter.format(charge.allowed || 0)}</td>
        <td className="text-center p-2">{currencyformatter.format(charge.deductible || 0)}</td>
        <td className="text-center p-2">{currencyformatter.format(charge.coinsurance || 0)}</td>
        <td className="text-center p-2">{currencyformatter.format(charge.copay || 0)}</td>
        <td className="text-center p-2">{currencyformatter.format(charge.adjustedAmount || 0)}</td>
        <td className="text-center p-2">{currencyformatter.format(charge.paid || 0)}</td>
      </tr>
    </>
  );
};

export default ChargeInfoRow;
