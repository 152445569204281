/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useState, useEffect } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import API from "api";
import { AppContext } from "../context/app-context";
import adminRoutes from "routes/adminRoutes";
import labRoutes from "routes/labRoutes";
import siteRoutes from "routes/siteRoutes";
import subAgentRoutes from "routes/subAgentRoutes";
import patientRoutes from "routes/patientRoutes";
import clientRoutes from "routes/clientRoutes";
import sidebarImage from "assets/img/sidebar-3.jpg";
import { Hub } from "aws-amplify";
import { debounce } from "debounce";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { medFlowInLocalStorage, patientDataInLocalStorage, formatLocations } from "utils";

import { Auth } from "aws-amplify";
import { useIdleTimer } from "react-idle-timer";
import IdleModal from "components/IdleModal";
import { useHistory } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { Storage } from "aws-amplify";
import { AMAZON_CLIENT_RESULT, CONFIG, filterRoutesPath, LOGO_IMAGE_PATH } from "../constant";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "components/Loader/Loader";
import AnalyticalDashbaord from "views/AnalyticalDashboard.js";
import awsconfig from "../aws-exports";
import { DataStore, syncExpression } from "@aws-amplify/datastore";
import { Employee, EligibiliyFiles, EmployeeOrder, EmployeeClaims, ProviderInfo } from "../models";
import Employees from "views/Employees";
import AdminOrders from "views/AdminOrders";
import { userCompanyID } from "utils";
import logoImage from "../assets/img/ltc_logo.png";
import { userCompanyLogo } from "utils";

function Admin() {
  const timeout = 1800000;
  const [showIdleModal, setShowIdleModal] = useState(false);
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState(+new Date());
  const [lastEvent, setLastEvent] = useState("Events Emitted on Leader");
  const [leader, setLeader] = useState(true);
  const [timedOut, setTimedOut] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  let history = useHistory();

  const handleCloseModal = () => {
    setShowIdleModal(false);
  };

  const handleLogOut = async () => {
    setShowIdleModal(false);
    try {
      await DataStore.clear();
      await Auth.signOut();
      medFlowInLocalStorage.clear();
      handlePause();
      history.push("/login");
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };

  const handleOnIdle = (event) => {
    setShowIdleModal(true);
  };

  const handleOnActive = (event) => {
    setTimedOut(false);
  };

  const handleOnAction = (event) => {
    setTimedOut(false);
  };

  const handleReset = () => reset();
  const handlePause = () => pause();
  const handleResume = () => resume();
  const handleStart = () => start();

  const {
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime,
    getTotalIdleTime,
    getLastIdleTime,
    isIdle,
    pause,
    resume,
    start,
    isLeader,
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: false,
    },
  });

  useEffect(() => {
    if (showIdleModal) {
      const timeout = setTimeout(() => {
        if (showIdleModal) {
          handleLogOut();
        }
      }, 300000); // TODO: - This time is the extra time you want to see how long they're idle for until they get logged out
      return () => clearTimeout(timeout);
    }
  }, [showIdleModal]);

  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const [userRoutes, setUserRoutes] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [admins, setAdmins] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [testFilter, setTestFilter] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [locations, setLocations] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subAgents, setSubAgents] = useState([]);
  const [successMessageText, setSuccessMessageText] = useState("");
  const [errorMessageText, setErrorMessageText] = useState("");
  const [userLocation, setUserLocation] = useState(null);
  const [userCompany, setUserCompany] = useState(null);
  const [userSubAgent, setUserSubAgent] = useState(null);
  const [employeeRecord, setEmployeeRecord] = useState(null);
  const [eligibilityFiles, setEligibilityFiles] = useState([]);
  const [providers, setProviders] = useState([]);
  const [eligibilityFileId, setEligibilityFileId] = useState("");
  const [logo, setLogo] = useState(logoImage);
  const [setting, setSetting] = useState({});
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.disabled) return null;
      if (prop.layout === "/admin") {
        return <Route path={prop.layout + prop.path} render={(props) => <prop.component {...props} />} key={key} />;
      } else {
        return null;
      }
    });
  };

  const fetchUserGroups = async (id = null) => {
    const response = await API.getUserFromLocalDB(id, user);
    setUsers(response || []);
    // console.log("In Fetching User Record");
    // const admins = await API.fetchUsersInGroup("Admins");
    // const employers = await API.fetchUsersInGroup("Employers");
    // const clients = await API.fetchUsersInGroup("Clients");
    // const subAgents = await API.fetchUsersInGroup("SubAgents");
    // return Promise.all([admins, employers, clients, subAgents]).then(
    //   (values) => [admins, employers, clients, subAgents]
    // );
  };

  const fetchAdmins = async () => {
    const admins = await API.fetchUsersInGroup("Admins");
    return admins;
  };

  const onSubscriptionTrigger = debounce(() => refreshDataOnSubscribe(), 6000);
  const onEligibilitySubscriptionTrigger = debounce(() => refreshEligibiltyFileDataOnSubscribe(), 1000);

  const refreshDataOnSubscribe = () => {
    console.log("REFRESH_LIST");
    fetchEmployees();
  };

  const refreshEligibiltyFileDataOnSubscribe = () => {
    console.log("REFRESH_LIST");
    fetchEligibityFiles();
  };
  const [user, setUser] = useState();

  // useEffect(()=>{
  //   DataStore.stop();
  // },[])

  useEffect(async () => {
    if (user && !user.isUser()) {
      const data = await API.getEmployeeClients(user.phone_number);

      if (data?.clientID) {
        const client = await getClientById(data.clientID);
        client?.logo && getFileFromStorage(client.logo, setLogo);
      }
    } else if (userCompanyLogo.get()) {
      setLogo(userCompanyLogo.get());
    }
  }, [user, companies]);

  const getFileFromStorage = async (file, setImage) => {
    if (file) {
      console.log("file", file);
      try {
        const path = `${LOGO_IMAGE_PATH}${file}`;
        userCompanyLogo.save(path);
        setImage(path);
        return true;
      } catch {
        console.log("Image loading fail");
      }
    } else {
      return false;
    }
  };

  const isReadOnly = () => !CONFIG.isLabType && user["phone_number"] === "+18185730434";

  useEffect(() => {
    let subscription = null;
    let eligibilityFileSubscrption = null;
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) {
        if (user.isAdmin() || user.isSite() || user.isUser() || user.isClient() || user.isSubAgent()) {
          let syncExpressionsByUserType = [
            syncExpression(Employee, () => {
              return (emp) => emp.clientID("eq", userCompanyID.get());
            }),
            syncExpression(EmployeeClaims, () => {
              return (emp) => emp.clientID("eq", userCompanyID.get());
            }),
            syncExpression(ProviderInfo, () => {
              return (emp) => emp.clientID("eq", userCompanyID.get());
            }),
          ];

          DataStore.configure({
            maxRecordsToSync: 90000,
            syncPageSize: 2000,
            ...(CONFIG.isLabType ? { syncExpressions: syncExpressionsByUserType } : {}),
          });
          await DataStore.stop();
          await DataStore.start();
          setUser(user);
          handleStart();
        } else {
          setLoading(false);
          setShowAlert(true);
        }
      } else {
        setLoading(false);
        console.log("push to login");
        history.push("/login");
      }
    };
    getUSer().then((res) => {
      try {
        subscription = DataStore.observe(Employee).subscribe((msg) => {
          onSubscriptionTrigger();
        });
        eligibilityFileSubscrption = DataStore.observe(EligibiliyFiles).subscribe((msg) => {
          onEligibilitySubscriptionTrigger();
        });
      } catch (err) {
        console.log("Error:-", err);
      }
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []);

  const fetchEmployees = async () => {
    const models = await API.getEmployees();
    setEmployees(models.filter((f) => f.email !== "adminmf@yopmail.com"));
  };

  const fetchLocations = async (value) => {
    const models = await API.getLocations(value);
    setLocations(formatLocations(models, companies));
  };
  const fetchSubAgents = async () => {
    const models = await API.getSubAgents();
    setSubAgents(models);
  };

  const fetchCompanies = async () => {
    const models = await API.getCompanies();
    setCompanies(models);
  };

  const fetchCompanySetting = async () => {
    const models = await API.getCompanySetting();
    setSetting(models);
  };
  const fetchEligibityFiles = async () => {
    const models = await API.getEligibilityFiles();
    setEligibilityFiles(models);
  };

  const fetchProviderInfo = async () => {
    const models = await API.getProviderInfo();
    setProviders(models);
  };

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainPanel.current.scrollTop = 0;
    if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  const fetchUserLocation = async () => {
    const location = await API.getUserSite();
    setUserLocation(location);
    setLocations([location]);
  };

  const fetchUserSubAgent = async () => {
    const subAgent = await API.getUserSubAgent();
    setUserSubAgent(subAgent);
    setSubAgents([subAgent]);
  };

  const fetchUserCompany = async () => {
    const company = await API.getUserCompany();
    const defaultSubAgent = await API.getUserDefaultSubAgent();

    setUserSubAgent(defaultSubAgent);
    setUserCompany(company);
    setCompanies([company]);
  };

  const fetchEmployeeID = async () => {
    const item = await API.getLoggedInUserEmployeeID(null, user.preferred_username);
    patientDataInLocalStorage.save(item);
    setEmployeeRecord(item);
  };

  const getClientById = (id) => {
    if (!id) return null;
    if (companies) {
      const client = companies.find((c) => c.id === id);
      if (client) return { ...client, label: client.name, value: client.id };
    }
    return null;
  };

  const getLocationById = (id) => {
    if (!id) return null;
    if (locations) {
      const location = locations.find((c) => c.id === id);
      if (location) return { ...location, label: location.name, value: location.id };
    }
    return null;
  };

  const getTotalOrders = () => {
    let ttlOrders = 0;
    if (!userCompanyID.get()) {
      ttlOrders = companies.reduce((num, c) => {
        if (c.totalOrders) {
          num = num + c.totalOrders;
        }

        return num;
      }, 0);
    }

    if (user?.isClient()) {
      ttlOrders = userCompany?.totalOrders || 0;
    } else if (user?.isSite()) {
      ttlOrders = userLocation?.totalOrders || 0;
    } else if (user?.isSubAgent()) {
      ttlOrders = userSubAgent?.totalOrders || 0;
    }
    return ttlOrders;
  };

  const getEligibilityFileObject = (params) => {
    const obj = {
      clientName: null,
      locationName: null,
      subAgentName: null,
      fileType: params.fileType,
      fileName: params.fileName,
      totalRecord: params.ttlRecord,
    };
    if (user?.isClient()) {
      Object.assign(obj, {
        clientID: userCompany.id,
      });
      nameData.clientName = userCompany.name;
    } else if (user?.isSite()) {
      Object.assign(obj, {
        clientID: userLocation.clientID,
        locationID: userLocation.id,
      });
      obj.clientName = companies.find((company) => company.id === userLocation.clientID).name;
      obj.locationName = userLocation.name;
    } else if (user?.isSubAgent()) {
      Object.assign(obj, {
        clientID: userSubAgent.clientID,
        locationID: userSubAgent.locationID,
        subAgentID: userSubAgent.id,
      });
      obj.clientName = companies.find((company) => company.id === userSubAgent.clientID).name;
      obj.locationName = locations.find((loc) => loc.id === userSubAgent.locationID).name;
      obj.subAgentName = userSubAgent.name;
    }
    return obj;
  };

  const updateTestAvailableQty = (Ids, testQty) => {
    const updateEmps = employees.map((m) => {
      if (Ids.includes(m.id)) {
        return { ...m, testAvailable: testQty };
      }
      return { ...m };
    });
    setEmployees(updateEmps);
  };

  const customRoutes = (routes, user) => {
    if (isReadOnly()) {
      return routes.filter((f) => !filterRoutesPath.includes(f.path));
    }
    if (user.phone_number === "+17777777777" || user.phone_number === "+18888888888") {
      return routes;
    }

    if (CONFIG.isLabType) {
      return routes.filter((f) => f.path !== "/users" && f.path !== "/companies");
    }

    return routes.filter((f) => f.path !== "/billing");
  };

  const contextSwitch = (role) => {
    if (user?.isAdmin()) {
      return {
        user,
        users,
        employees,
        locations,
        companies,
        subAgents,
        users,
        userSubAgent,
        eligibilityFiles,
        providers,
        setting,
        updateTestAvailableQty: (Ids, testQty) => updateTestAvailableQty(Ids, testQty),
        isReadOnly: () => isReadOnly(),
        getTotalOrders: () => getTotalOrders(),
        getEligibilityFileObject: (obj) => getEligibilityFileObject(obj),
        getClientById: (id) => getClientById(id),
        getLocationById: (id) => getLocationById(id),
        resetEmployees: () => fetchEmployees(),
        resetSubAgents: () => fetchSubAgents(),
        resetLocations: () => fetchLocations(),
        resetCompanies: () => fetchCompanies(),
        resetCompanySetting: () => fetchCompanySetting(),
        resetUsers: () => fetchUserGroups(),
        resetProviders: () => fetchProviderInfo(),
        admins,
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
      };
    }
    if (user?.isClient()) {
      return {
        user,
        userCompany,
        users,
        employees,
        locations,
        companies,
        subAgents,
        userSubAgent,
        eligibilityFiles,
        providers,
        setting,
        updateTestAvailableQty: (Ids, testQty) => updateTestAvailableQty(Ids, testQty),
        isReadOnly: () => isReadOnly(),
        getTotalOrders: () => getTotalOrders(),
        getClientById: (id) => getClientById(id),
        getLocationById: (id) => getLocationById(id),
        resetEmployees: () => fetchEmployees(),
        resetSubAgents: () => fetchSubAgents(),
        resetLocations: () => fetchLocations(),
        resetCompanies: () => fetchCompanies(),
        resetCompanySetting: () => fetchCompanySetting(),
        resetUsers: () => fetchUserGroups(),
        resetProviders: () => fetchProviderInfo(),
        admins,
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
      };
    }
    if (user?.isSite()) {
      return {
        userLocation,
        user,
        employees,
        locations,
        users,
        subAgents,
        companies,
        eligibilityFiles,
        setting,
        updateTestAvailableQty: (Ids, testQty) => updateTestAvailableQty(Ids, testQty),
        isReadOnly: () => isReadOnly(),
        getTotalOrders: () => getTotalOrders(),
        getLocationById: (id) => getLocationById(id),
        getClientById: (id) => getClientById(id),
        resetEmployees: () => fetchEmployees(),
        resetSubAgents: () => fetchSubAgents(),
        resetUsers: () => fetchUserGroups(),
        testFilter,
        resetCompanies: () => fetchCompanies(),
        resetCompanySetting: () => fetchCompanySetting(),
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
      };
    }
    if (user?.isSubAgent()) {
      return {
        userSubAgent,
        user,
        employees,
        users,
        subAgents,
        companies,
        locations,
        eligibilityFiles,
        setting,
        isReadOnly: () => isReadOnly(),
        updateTestAvailableQty: (Ids, testQty) => updateTestAvailableQty(Ids, testQty),
        getTotalOrders: () => getTotalOrders(),
        getClientById: (id) => getClientById(id),
        resetEmployees: () => fetchEmployees(),
        resetUsers: () => fetchUserGroups(),
        testFilter,
        resetCompanies: () => fetchCompanies(),
        resetCompanySetting: () => fetchCompanySetting(),
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
      };
    }
    if (user?.isUser()) {
      return {
        userLocation,
        employeeRecord,
        user,
        locations,
        userSubAgent,
        subAgents,
        isReadOnly: () => isReadOnly(),
        getTotalOrders: () => getTotalOrders(),
        resetEmployeeRecord: () => fetchEmployeeID(),
        resetUsers: () => fetchUserGroups(),
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
      };
    }
  };

  const adminListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        fetchCompanies();
        fetchCompanySetting();
        fetchLocations();
        fetchEmployees();
        fetchSubAgents();
        fetchProviderInfo();
        fetchEligibityFiles();
        setUserRoutes(customRoutes(CONFIG.isLabType ? labRoutes : adminRoutes, user));
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };

  const clientListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        fetchUserCompany();
        fetchCompanySetting();
        fetchLocations(true);
        fetchEmployees();
        fetchSubAgents();
        fetchProviderInfo();
        fetchEligibityFiles();
        setUserRoutes(customRoutes(CONFIG.isLabType ? labRoutes : clientRoutes, user));
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };

  const locationListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        fetchCompanies();
        fetchUserLocation();
        fetchCompanySetting();
        fetchSubAgents();
        fetchLocations();
        fetchEmployees();
        fetchEligibityFiles();
        setUserRoutes(siteRoutes);
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };

  const subAgentListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        fetchCompanies();
        fetchUserSubAgent();
        fetchCompanySetting();
        fetchLocations();
        fetchEmployees();
        fetchEligibityFiles();
        setUserRoutes(subAgentRoutes);
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };
  const userListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        fetchEmployeeID();
        fetchUserLocation();
        fetchUserSubAgent();
        setUserRoutes(patientRoutes);
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };

  React.useEffect(() => {
    user?.isAdmin() && adminListener();
    user?.isClient() && clientListener();
    user?.isSite() && locationListener();
    user?.isUser() && userListener();
    user?.isSubAgent() && subAgentListener();
  }, [user]);

  return (
    <>
      {!loading ? (
        <div className="wrapper">
          {showAlert && (
            <SweetAlert
              show={showAlert}
              error
              title="Error"
              onConfirm={async () => {
                await Auth.signOut();
                window.location.reload();
              }}
            >
              Your account does not have access to the Portal
            </SweetAlert>
          )}
          {successMessageText && (
            <SweetAlert
              show={successMessageText ? true : false}
              success
              title="Success"
              onConfirm={() => setSuccessMessageText("")}
            >
              {successMessageText}
            </SweetAlert>
          )}
          {errorMessageText && (
            <SweetAlert
              show={errorMessageText ? true : false}
              error
              title="Error"
              onConfirm={() => setErrorMessageText("")}
            >
              {errorMessageText}
            </SweetAlert>
          )}
          <Sidebar
            color={color}
            image={""}
            routes={userRoutes}
            user={user}
            testFilter={testFilter}
            setTestFilter={setTestFilter}
            imageName={logo}
          />

          <div className="main-panel" ref={mainPanel}>
            <AdminNavbar
              routes={userRoutes}
              role={user?.roles[0]}
              user={user}
              userLocation={userLocation}
              userCompany={userCompany}
              eligibilityFiles={eligibilityFiles}
              setErrorMessageText={setErrorMessageText}
              setSuccessMessageText={setSuccessMessageText}
            />
            <div className="content">
              <AppContext.Provider value={contextSwitch(user?.roles[0])}>
                <Switch>{getRoutes(userRoutes)}</Switch>
                {user && !user?.isUser()
                  ? window.location.pathname === "/admin" && <Employees />
                  : user && window.location.pathname === "/admin" && <AdminOrders />}
              </AppContext.Provider>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <IdleModal
        showChildModal="showChildModal"
        showIdleModal={showIdleModal}
        animation={true}
        handleLogOut={handleLogOut}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
}

export default Admin;
