import React, { useEffect } from "react";
import { useQuery, queryCache } from "react-query";
import { EMPLYEE_LOGS } from "./hookConstant";
import api from "api";
const useEmployeeLogs = (id) => {
  const { data, isLoading, error, refetch } = useQuery([EMPLYEE_LOGS, id], () => api.getEmployeeLogs(id), {
    cacheTime: 0,
  });

  useEffect(() => {
    refetch(); // Trigger a refetch on component rerender
  }, [id, refetch]);

  return {
    data,
    isLoading,
    error,
  };
};

export default useEmployeeLogs;
