import React, { useState, useEffect } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import api from "api";
import { formatDateMDY } from "utils";
import { usersNo } from "constant";
import Loader from "components/Loader/Loader";
import { formatDateMDYTime } from "utils";

const PreviousNotes = (props) => {
  const { id, user } = props;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);
    const logs = await api.getEmployeeLogs(id);

    setItems(logs.filter((f) => f.eventType === "MemberUpdated" && f.changeObject && f.changeObject.newRecord?.note));
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const TableRow = ({ log, index }) => {
    const obj = log.changeObject.newRecord;
    return (
      <tr>
        <td>{index + 1}</td>
        <td className="w-15">{formatDateMDY(log.createdAt)}</td>

        <td className="ellipsis w-50" style={{ textAlign: "center" }} title={obj.note?.S || ""}>
          {obj.note?.S || ""}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={obj.noteAddedBy?.S || "System"}>
          {obj.noteAddedBy?.S || "System"}
        </td>

        {/* <td
          onClick={async () => {
            await api.deleteLogRecord(log.id);
          }}
        >
          <i className="fas fa-trash cursor-pointer" />
        </td> */}
      </tr>
    );
  };

  if (loading) return <Loader />;

  if (items.length > 0) {
    return (
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header className="fw-bold">Previous Note's</Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <Table className="table">
                  <thead>
                    <tr>
                      <th className="ellipsis w-10">SQ.</th>
                      <th className="ellipsis">DATE</th>
                      {/* {/* <th className="border-0 centered">Action</th>  */}
                      <th className="border-0 centered ellipsis w-50">NOTE</th>
                      <th className="border-0 centered ellipsis w-30">USER NAME</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, i) => {
                      return <TableRow key={i} index={i} log={item} />;
                    })}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  return <></>;
};

export default PreviousNotes;
