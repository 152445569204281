import React, { useState, useContext } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import api from "api";
import { Auth } from "aws-amplify";
import { AppContext } from "../context/app-context";
import Filter from "../components/Filter";
import TimeFilter from "../components/TimeFilter";
import UserModal from "components/Users/UserModal";
import UserPasswordModal from "components/Users/UserPasswordModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import TDData from "components/Table/TDData";
import {
  NEW_PROVIDER_OBJECT,
  USER_ROLE_TYPE,
  PAGE_LIMIT,
  HEALTH_AND_SAFTEY,
  USER_ROLE_TITLE,
  CONFIG,
  TABLE_QUICK_TOOLS,
} from "../constant";
import { PROVIDER_PERSONALIZE } from "constants/personalization";
import { t } from "../stringConstants";
import { personalizationLocalStorage, formatNumber } from "../utils";
import "../../src/tableStyling.css";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import Icon from "../components/Icon";
import ProviderModal from "components/Modal/ProviderModal";
import Loader from "components/Loader/Loader";
import MainTable from "components/Table/MainTable";

const Provider = (props) => {
  const [users, setUsers] = useState([]);
  const [providers, setProviders] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [newUser, setNewUser] = useState(NEW_PROVIDER_OBJECT);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});

  const appContext = useContext(AppContext);
  const [fullscreen, setFullscreen] = useState(true);
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [setting, setSetting] = useState(null);
  const [checkboxes, setCheckboxes] = useState([]);
  const quickTools = [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.edit, TABLE_QUICK_TOOLS.delete];

  const searchTerms = ["Name"];

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const handleCheckboxChange = (e, row) => {
    const filteredList = checkboxes.filter((c) => c !== row.id);
    if (e.target.checked) {
      filteredList.push(row.id);
    }
    setCheckboxes(filteredList);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "userRole") {
          return obj.userRole && USER_ROLE_TITLE[obj.userRole].toLowerCase().includes(filters[key].toLowerCase());
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    // console.log("FROM NESTED FILTER", models);
    return models;
  };

  const createProvider = async (user, defaultSetting) => {
    try {
      setLoading(true);
      let providerDefaultSetting = defaultSetting;
      if (user.isNew) {
        const result = await api.newProviderInfo(user);
        Object.keys(defaultSetting).map((m) => {
          providerDefaultSetting[m] = result.id;
        });
        appContext.showSuccessMessage(t("providerCreatedSuccessfully"));
      } else {
        const result = await api.updateProviderInfo(user);
        appContext.showSuccessMessage(t("providerUpdatedSuccessfully"));
      }
      await api.updateCompnayDefaultSetting(providerDefaultSetting);
      await appContext.resetCompanySetting();
      await appContext.resetProviders();
      setLoading(false);
    } catch (error) {
      console.log("ERROR:User-", error);
      appContext.showErrorMessage(error.message);
      setOpenCreator(true);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
  };

  const handlePasswordClose = () => {
    setOpenPasswordModal(false);
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = (users) => {
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredProviders);

  const getPageNumbers = (users) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(users);

  useEffect(async () => {
    if (appContext.providers && appContext.providers.length > 0) {
      setProviders(appContext.providers);
      setFilteredProviders(appContext.providers);
      const res = await api.getCompanySetting();
      setSetting(res);
    }
  }, [appContext.providers]);

  useEffect(() => {
    if (filteredProviders.length > 0) setFilteredProviders(sortUsers(filteredProviders, sortBy));
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (providers.length > 0) {
      setFilteredProviders(nestedFilter(providers, filter));
      setCurrentPage(1);
    }
  }, [filter]);

  useEffect(() => {
    setPersonalize(personalizationLocalStorage.get(appContext?.user, "provider", PROVIDER_PERSONALIZE));
  }, [appContext?.company]);

  if (filteredProviders.length > 0) {
    pageNumbers = getPageNumbers(filteredProviders);
  }

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
    handleChangePage(1);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = personalizationLocalStorage.saveAs(data, "systemUser");
    setOpenPersonalizationModal(false);
    personalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(appContext.user.phone_number, personalisationData);
  };

  const handleOpenCreateModal = () => {
    const obj = NEW_PROVIDER_OBJECT;
    setNewUser({ ...obj, isNew: true });
    setOpenCreator(true);
  };

  const handleEditUser = (user) => {
    const obj = {
      ...user,
      isNew: false,
    };
    setNewUser(obj);
    setOpenCreator(true);
  };

  const handleConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);

    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    try {
      setLoading(true);
      await api.deleteProviderInfo(userToDelete.id);
      setUserToDelete(null);
      appContext.showSuccessMessage("Provider Deleted Successfully");
      appContext.resetProviders();
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.edit:
        handleEditUser(row);
        break;

      case TABLE_QUICK_TOOLS.delete:
        setOpenDeleteConfirmation(true);
        setUserToDelete(row);
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  {t("provider")} ({filteredProviders ? formatNumber(filteredProviders.length) : providers.length})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />

                  <Icon
                    handleClick={() => handleOpenCreateModal()}
                    title={"Create Provider"}
                    label={"Create"}
                    iconType={"addUserIcon"}
                  />
                </div>

                {showFilter && (
                  <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} isUsersTable={true} />
                )}

                <Row className="pull-right" style={{ borderWidth: 1 }}>
                  <Col></Col>
                  <Col style={{ textAlign: "right" }} md={{ span: 3, offset: 1 }} xl={{ span: 3, offset: 1 }}>
                    Page
                    <ul className="createButtons pr-0" style={{ display: "inline" }}>
                      <i
                        className="fas fa-angle-left"
                        style={{ color: "#585858" }}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          e.target.style.color = "black";
                        }}
                        onMouseLeave={(e) => (e.target.style.color = "#585858")}
                        onClick={() => {
                          currentPage !== 1 && handleChangePage(currentPage - 1);
                        }}
                      />
                      {/* {currentPage !== 1 && "..."} */}
                      {currentPage} of {pageNumbers.length}
                      {/* {pageNumbers.length >= currentPage && "..."} */}
                      <i
                        className="fas fa-angle-right"
                        style={{ color: "#585858" }}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          e.target.style.color = "black";
                        }}
                        onMouseLeave={(e) => (e.target.style.color = "#585858")}
                        onClick={() => {
                          currentPage !== pageNumbers.length && handleChangePage(currentPage + 1);
                        }}
                      />
                    </ul>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  columns={personalize}
                  rows={usersToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  widthToSkip={160}
                  selectedRows={checkboxes}
                  tools={quickTools}
                  handleCellClick={handleCellClick}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      {openCreator && (
        <ProviderModal
          show={openCreator}
          user={newUser}
          handleClose={handleClose}
          handleSave={createProvider}
          setting={setting}
        />
      )}
      {openPasswordModal && (
        <UserPasswordModal user={newUser} handleClose={handlePasswordClose} handleSave={handleResetPassword} />
      )}
      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete Provider"
          message="Are you sure, you want to remove Provider?"
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default Provider;
