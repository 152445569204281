import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import api from "api";
import { AppContext } from "context/app-context";
import { INSURANCE_PROVIDER } from "constant";
import { Storage } from "aws-amplify";
import ProcedureInfoSection from "./components/ProcedureInfoSection/ProcedureInfoSection";
import { CONFIG } from "constant";
import PdfViewer from "../PdfViewer";
import Loader from "components/Loader/Loader";

const EraModal = (props) => {
  const { user, handleClose } = props;
  const [show, setShow] = useState("");
  const [eraList, setEraList] = useState([]);
  const [openPdf, setOpenPdf] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    getCliData();
  }, []);

  const createCustomObjectURL = (file) => {
    const baseUrl = `${window.location.origin}/admin`; // Your custom base URL
    const objectUrl = URL.createObjectURL(file);

    // Get the unique identifier for the object URL
    const objectUrlId = objectUrl.split("/").pop();

    // Create a custom URL with the desired path or location
    const customUrl = baseUrl + "/" + objectUrlId;

    return customUrl;
  };

  const handleOpenEraFile = async (fileName) => {
    try {
      const res = await Storage.get(fileName, {
        bucket: CONFIG.eligibilityBucket,
      });

      // setOpenPdf(res);

      // const baseUrl = res.split("?")[0];
      // setOpenPdf(baseUrl);

      window.open(`${res}`);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const formatEraListView = (eraList, user) => {
    let list = [];
    eraList.forEach((eraData) => {
      user.proc_array.forEach((obj) => {
        const { proc_code, from_date, isClaimProcess } = obj;
        if (isClaimProcess) {
          const eraCharge = eraData.charge.find((f) => proc_code === f.proc_code);
          if (eraCharge) {
            const {
              check_number,
              paid_date,
              prov_name,
              prov_npi,
              prov_taxid,
              prov_addr_1,
              prov_city,
              prov_state,
              prov_zip,
              payer_icn,
            } = eraData;
            const adjustedAmount = eraCharge.adjustment?.reduce((prev, ad) => {
              if (
                (ad.code === "45" && ad.group == "CO") ||
                (ad.code === "253" && ad.group == "CO") ||
                (ad.code === "45" && ad.group == "PR")
              ) {
                return parseFloat(ad.amount) + prev;
              }
            }, 0);
            const deductible = eraCharge.adjustment?.reduce((prev, ad) => {
              if (ad.code === "1" && ad.group == "PR") {
                return parseFloat(ad.amount) + prev;
              }
            }, 0);
            const coinsurance = eraCharge.adjustment?.reduce((prev, ad) => {
              if (ad.code === "2" && ad.group == "PR") {
                return parseFloat(ad.amount) + prev;
              }
            }, 0);
            const copay = eraCharge.adjustment?.reduce((prev, ad) => {
              if (ad.code === "3" && ad.group == "PR") {
                return parseFloat(ad.amount) + prev;
              }
            }, 0);
            list.push({
              ...eraCharge,
              status: eraCharge.paid === "0" ? "Denied" : "Paid",
              check_number,
              paid_date,
              prov_name,
              prov_npi,
              prov_taxid,
              prov_addr_1,
              prov_city,
              prov_state,
              prov_zip,
              payer_icn,
              adjustedAmount,
              deductible,
              coinsurance,
              copay,
            });
          }
        } else {
          list.push({ ...obj, from_dos: from_date, status: "Pending" });
        }
      });
    });
    return list.sort((a, b) => (b.status > a.status ? 1 : a.status > b.status ? -1 : 0));
  };

  const getCliData = async () => {
    setLoading(true);
    const era = await api.getClaimERA(user);
    // setEraList(formatEraListView(era, user));
    if (era.length > 0) {
      for (let i = 0; i < era.length; i++) {
        const eraData = era[i];
        const path = await api.getPatientERA(eraData);
        Object.assign(era[i], {
          eraPdfPath: path,
          status: user.status || "",
        });
      }
      era.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }

    setEraList(era);
    setLoading(false);
  };

  return (
    <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"2xl"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          {`ERA Detail ${user.pat_name_f ? `(${user.pat_name_f} ${user.pat_name_l})` : ""}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="crew-member-profile">
          <div className="crew-member-profile-inner">
            <div className="d-md-flex  justify-content-between">
              <Col md="12" className="border-left-1">
                <ProcedureInfoSection dataList={eraList} handleOpenEraFile={handleOpenEraFile} />
              </Col>
            </div>
          </div>
        </div>
        {loading && <Loader />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
      </Modal.Footer>

      {openPdf && <PdfViewer url={openPdf} />}
    </Modal>
  );
};

export default EraModal;
