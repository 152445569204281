import React from "react";
import { Table } from "react-bootstrap";
import { ERA_PROCEDURE_CODE_HEADER } from "constant";
import ProcedureInfoRow from "./ProcedureInfoRow";

const ProcedureInfoSection = (props) => {
  const { dataList, handleOpenEraFile } = props;

  return (
    <>
      <div className="personal-info-wrapper pt-3 px-3">
        {/* <div className="d-flex justify-content-between font-bold">
          <div className="d-flex flex-column">
            <span>{newUser.bill_name || ""}</span>
            <span>{newUser.bill_addr_1 || ""}</span>
            <span>{newUser.bill_addr_2 || ""}</span>
            <span>
              {newUser.bill_city || ""}, {newUser.bill_state || ""} {newUser.bill_zip || ""}
            </span>
          </div>
          <div className="d-flex flex-column">
            <span>NPI#: {newUser.bill_npi || ""}</span>
            <span>Date#: {formatDateMDY(newUser.from_date)}</span>
            <span>EFT#: 0</span>
            <span>Tax ID#: {newUser.bill_taxid || ""}</span>
          </div>
        </div> */}
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="text-decoration-underline text-black mt-0">ERA Detail</h4>
        </div>
        <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
          <div className="data-listing-form">
            <div className="table-wrapper">
              <Table className="table">
                <thead>
                  <tr className="data-listing-row">
                    <th />
                    {ERA_PROCEDURE_CODE_HEADER.map((title) => (
                      <th className="centered">{title}</th>
                    ))}
                    <th className="centered">Download</th>
                  </tr>
                </thead>
                <tbody>
                  {dataList?.map((data, index) => (
                    <ProcedureInfoRow key={index} data={data} index={index} handleOpenEraFile={handleOpenEraFile} />
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcedureInfoSection;
