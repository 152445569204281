import React, { useState } from "react";
import { Card, Table } from "react-bootstrap";
import ChargeInfoRow from "./ChargeInfoRow";
import { ERA_PROCEDURE_CODE_DETAIL_HEADER } from "constant";
import { formatDateMDY } from "utils";

const ProcedureInfoRow = (props) => {
  const { data, index, handleOpenEraFile } = props;
  const [open, setOpen] = useState(false);
  const paidBy = `${data.payer_name}
  ${data.status_code === "1" ? " as Primary" : data.status_code === "2" ? " as Secondary" : ""}`;
  return (
    <>
      <tr index={index} className={open ? "border-bottom-0" : ""}>
        <td className={`${open ? "border-bottom-0" : ""}`}>
          <i
            onClick={() => setOpen(!open)}
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.color = "#8B0000";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            className={open ? "fas fa-minus" : "fas fa-plus"}
          />
        </td>
        <td className={`text-center ${open ? "border-bottom-0" : ""}`}>{formatDateMDY(data.paid_date)}</td>
        <td className={`text-center ${open ? "border-bottom-0" : ""}`}>{data.payer_icn}</td>
        <td className={`text-center ${open ? "border-bottom-0" : ""} ellipsis`}>{data.prov_name}</td>
        <td className={`text-center ${open ? "border-bottom-0" : ""}`}>{data.prov_npi}</td>
        <td className={`text-center ${open ? "border-bottom-0" : ""}`}>{data.check_number}</td>
        <td className={`text-center ${open ? "border-bottom-0" : ""} text-capitalize`}>
          {data.total_paid > 0 ? "Paid" : "Denied"}
        </td>
        <td className={`text-center ${open ? "border-bottom-0" : ""} ellipsis`} title={paidBy}>
          {paidBy}
        </td>
        <td
          className={`table-column ellipsis linkedImage ${open ? "border-bottom-0" : ""}`}
          onClick={() => handleOpenEraFile(data.eraPdfPath)}
        >
          <i className="fas fa-image" />
        </td>
      </tr>
      {open && (
        <tr>
          <td colSpan={"12"} className="expended-table-child">
            <Card className="striped-table-card" style={{ backgroundColor: "whitesmoke" }}>
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table">
                      <thead>
                        <tr>
                          {ERA_PROCEDURE_CODE_DETAIL_HEADER.map((title) => (
                            <th className="centered p-2">{title}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data.charge.map((chargeData, i) => {
                          return <ChargeInfoRow key={i} chargeData={chargeData} index={index} />;
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </td>
        </tr>
      )}
    </>
  );
};

export default ProcedureInfoRow;
