import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { Bounce } from "react-activity";
import API from "api";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { checkValidity, sortingFilterInLC } from "utils";
import { t } from "stringConstants";
import Filter from "components/Filter";
import MFPagination from "components/Pagination/MFPagination";
import {
  newLocationObject,
  TEST_ORDER_LIMIT_CATEGORY_VALUE,
  PAGE_LIMIT,
  CONFIG,
  USER_TYPE_USER,
  USER_TYPE_ADMIN,
  USER_TYPE_CLIENT,
} from "constant";
import Loader from "components/Loader/Loader";
import { Auth } from "aws-amplify";
import { emailMsgToCreatedClient } from "utils";
import { INSURANCE_PROVIDER } from "constant";
import { Link } from "react-router-dom";
import { formatOrder } from "utils";
import moment from "moment";
import { USER_TYPE_SUBAGENT } from "constant";
import { Remove_PayerID } from "constant";
import { CLIENT_PERSONALIZE } from "constants/personalization";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import { tdCheckBox } from "utils";
import Icon from "components/Icon";
import { formatNumber } from "utils";
import { TABLE_QUICK_TOOLS } from "constant";
import MainTable from "components/Table/MainTable";
import { CLIENTS_DROPDOWN_OPTIONS } from "constant";
import BrmCompanyModal from "./BrmCompanyModal";
import { PERSONALIZATION } from "../../constant";
import { BRM_CLIENT_PERSONALIZE } from "constants/personalization";
import { draggablePersonalizationLocalStorage } from "utils";
import api from "api";
import { CHECKBOX_COLORS } from "constant";
import { CHECKBOX_ICON_OPTIONS } from "constant";
import { CHECKBOX_SHAPES } from "constant";
import { CHECKBOX_SIZES } from "constant";
import { tdCheckBoxIcon } from "utils";

const BrmCompany = () => {
  const appContext = useContext(AppContext);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectItem, setSelectItem] = useState(newLocationObject);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const quickTools = [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.edit];

  const searchTerms = ["Name", "Contact Name", "Contact Email", "Contact Phone"];

  const sortData = (schs, sortParam) => {
    if (!sortDescending) {
      return [...schs].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...schs].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  // useEffect(async () => {
  //   if (INSURANCE_PROVIDER.length > 0) {
  //     const filterData = INSURANCE_PROVIDER.filter(
  //       (f) => !Remove_PayerID.includes(f.payerid)
  //     );
  //     console.log("filterData", filterData, INSURANCE_PROVIDER);
  //     // await API.addJSON(filterData);
  //   }
  // }, [INSURANCE_PROVIDER]);

  const currentItems = (locs) => {
    if (!locs || locs.length === 0) return [];
    const indexOfLastUser = currentPage * PAGE_LIMIT;
    const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
    return locs.slice(indexOfFirstUser, indexOfLastUser);
  };

  let locationsToMap = currentItems(filteredLocations);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(locations);

  if (filteredLocations && filteredLocations.length > 0) {
    pageNumbers = getPageNumbers(filteredLocations);
  }

  useEffect(() => {
    const sortLS = sortingFilterInLC.get();
    if (sortLS.clients && sortLS.clients.sortBy) {
      setSortBy(sortLS.clients.sortBy);
      setSortDescending(sortLS.clients.sortDescending);
    }
    // if (sortLS.clients && sortLS.clients.filter) {
    //   setFilter(sortLS.clients.filter);
    // }
    setPersonalize(draggablePersonalizationLocalStorage.get(appContext.user, "brmCompany", BRM_CLIENT_PERSONALIZE));
  }, [appContext?.company]);

  const getBRMCompanies = async () => {
    const clients = await API.getBRMCompanies();
    formatClient(clients);
    try {
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  useEffect(() => {
    getBRMCompanies();
  }, []);

  const formatClient = (clients) => {
    setLocations(clients);
    setFilteredLocations(sortData(nestedFilter(clients, filter), sortBy));
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.clients = { sortBy, sortDescending, filter };
    sortingFilterInLC.save(pervSortLS);
  };

  useEffect(() => {
    if (locations.length > 0) {
      setFilteredLocations(sortData(nestedFilter(locations, filter), sortBy));
      setCurrentPage(1);
    }
  }, [filter]);

  useEffect(() => {
    if (filteredLocations.length > 0) {
      handleSaveFilter();
      setFilteredLocations(sortData(filteredLocations, sortBy));
    }
  }, [sortBy, sortDescending]);

  const handleConfirmDelete = async (isConfirm) => {
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      const isDeleted = await API.deleteClient(itemToDelete.id);
      if (isDeleted) {
        //await API.deleteUser(itemToDelete.phone_number);
        appContext.resetCompanies();
        appContext.showSuccessMessage("Client deleted successfully");
      } else {
        appContext.showErrorMessage("Client can't be deleted because few Members are linked with it");
      }
      setItemToDelete(null);
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const createUser = async (newUser) => {
    const firstName = newUser.firstName;
    const lastName = newUser.lastName;
    const username = `${newUser.phoneNumber}_${newUser.code}`;
    const attributePayload = {
      email: newUser.email,
      preferred_username: username,
      phone_number: newUser.phoneNumber,
      "custom:role": "Clients",
      "custom:autoConfirm": "true",
      "custom:firstName": firstName,
      "custom:lastName": lastName,
      "custom:note": "System",
      "custom:labID": newUser.clientID,
    };

    try {
      const signUp = await Auth.signUp({
        username: username,
        password: "Test1234!",
        attributes: attributePayload,
      });

      console.log("signup", signUp);

      //   const message = emailMsgToCreatedClient(
      //     newUser.contact_name,
      //     newUser.phone_number.replaceAll(/[^0-9]/gi, ""),
      //     newUser.password
      //   );

      //   await API.sendEmail([
      //     {
      //       email: newUser.contact_email,
      //       subject: "SafeCamp LTC Team",
      //       msg: message,
      //     },
      //   ]);
      return true;
    } catch (error) {
      appContext.showErrorMessage(error.message);
      return false;
    }
  };

  const OnHandleLocation = async (loc) => {
    const findVal = locations.find((f) => loc.id !== f.id && checkValidity(f.name) == checkValidity(loc.name));
    if (findVal) {
      appContext.showErrorMessage("Company Name already exist");
      return;
    }
    setOpenCreateModal(false);

    try {
      setLoading(true);

      if (loc.isNew) {
        const res = await API.createNewLab(loc, appContext.user);
        if (res) {
          const created = await createUser({ ...loc, ...res });
          appContext.showSuccessMessage("Company Created Successfully");
        }
      } else {
        await API.updateBRMCompany(loc);
        appContext.showSuccessMessage("Company Updated Successfully");
      }
      getBRMCompanies();
      setLoading(false);
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(err.message);
    }
  };

  const handleEdit = (item) => {
    setSelectItem({ ...item, isNew: false });
    setOpenCreateModal(true);
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);

    const models = targetArray.filter((obj) => {
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    setOpenPersonalizationModal(false);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "client");
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await API.udpatePersonalization(appContext.user.phone_number, personalisationData);
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const handleActiveToggle = async () => {
    if (checkboxes.length === 0) {
      appContext.showErrorMessage("Atleast select one company!");
      return;
    }

    try {
      setLoading(true);
      const companiesToChange = locations.filter((com) => checkboxes.includes(com.id));

      for (let i = 0; i < companiesToChange.length; i++) {
        const company = companiesToChange[i];
        await api.updateBRMCompanyActiveToggle(company.id, !company.isActive || false);
      }

      getBRMCompanies();
      setCheckboxes([]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      appContext.showErrorMessage(error.message);
    }
  };

  const tdFormat = (item, user) => {
    return user[item];
  };

  const renderTd = (item, user) => {
    if (item.itemKey === "isActive")
      return user.isActive
        ? tdCheckBoxIcon(CHECKBOX_ICON_OPTIONS.tick, CHECKBOX_COLORS.green, CHECKBOX_SHAPES.circle, CHECKBOX_SIZES.md)
        : tdCheckBoxIcon(CHECKBOX_ICON_OPTIONS.cross, CHECKBOX_COLORS.gray, CHECKBOX_SHAPES.circle, CHECKBOX_SIZES.md);
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormat(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.edit:
        if (row.id !== "5f696a38-55e0-43d0-8bbb-671884a09ae6" && row.id !== "010e1818-b30b-49a5-a04c-dd7b9597e9b2") {
          handleEdit(row);
        }
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("companies")} ({filteredLocations ? formatNumber(filteredLocations.length) : 0})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={() => setOpenCreateModal(true)}
                    title={t("createCompany")}
                    label={"Create"}
                    iconType={"addUserIcon"}
                  />

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />

                  <Icon
                    handleClick={() => handleActiveToggle()}
                    title={t("activeToggle")}
                    label={t("activeToggle")}
                    iconType={"acknowledgedPositiveIcon"}
                  />
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination currentPage={currentPage} handleChangePage={handleChangePage} totalPages={pageNumbers} />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  cssClass={locations.length === 0 && "overFlow-y-hidden"}
                  columns={personalize}
                  rows={locationsToMap}
                  individualRowCssClass={(row) => (!row.isActive ? "dim-bg" : "")}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={"brmCompany"}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={160}
                  selectedRows={checkboxes}
                  tools={quickTools}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}

          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Delete Client"
              message="Are you sure, you want to remove Client?"
              handleConfirm={handleConfirmDelete}
            />
          )}

          {openPersonalizationModal && (
            <PersonalizationModal
              data={JSON.stringify(personalize)}
              handleChange={handlePersonalization}
              show={openPersonalizationModal}
              handleClose={() => setOpenPersonalizationModal(false)}
            />
          )}

          {openCreateModal && (
            <BrmCompanyModal
              handleClose={() => {
                setSelectItem(newLocationObject), setOpenCreateModal(false);
              }}
              selectItem={selectItem}
              handleSave={OnHandleLocation}
              companies={locations}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BrmCompany;
