import React, { useState, useContext, useRef } from "react";
import { Card, Table, Row, Col, Form } from "react-bootstrap";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import api from "api";
import { AppContext } from "../context/app-context";
import SweetAlert from "react-bootstrap-sweetalert";
import Filter from "../components/Filter";
import HeaderItem from "components/Table/HeaderItem";
import EmployeeModal from "components/Employee/EmployeeModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ExportToExcel from "components/ExportToExcel";

import {
  EmptyUserObj,
  PAGE_LIMIT,
  GROUP_TYPES,
  EMPLOYEE_HEADERS,
  CONFIRMATION_TYPE,
  STATUS,
  MIN_TEST_QUANTITY,
  INSURANCE_PROVIDER,
  MEDICATRE_IDS,
  CONFIG,
  countryListAllIsoData,
  EMPLYEE_STATUS,
  PROC_CODE_OPT,
  DIAGNOSIS_OPTIONS,
  PARSE_FILTER_KEY_NAME,
  PATIENTS_DROPDOWN_OPTIONS,
  TABLE_QUICK_TOOLS,
  usersNo,
  claimSubmitStatus,
} from "../constant";
import { NEW_PERSONALIZE, LAB_MEMBER_PERSONALIZE } from "../constants/personalization";
import { t } from "../stringConstants";
import {
  formatDOB,
  setPhoneNo,
  parseBooleanValue,
  validatePhone,
  getPhoneNumber,
  isValidDob,
  isValidEmail,
  getValidGender,
  getVaccinated,
  getValidDep,
  formatDateMDY,
  personalizationLocalStorage,
  tdEmail,
  tdPhone,
  tdCheckBox,
  calculateTdWidth,
  sortingFilterInLC,
  changeSort,
  sortList,
  getValidSpaces,
  formatEmployeesData,
  formatTimeZone,
  importPhoneNoFormat,
  formatDate,
  currentDate,
} from "utils";
import MFPagination from "components/Pagination/MFPagination";
import EmployeeImportModal from "components/Modal/EmployeeImportModal";
import "../../src/tableStyling.css";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import EditIcon from "../assets/img/edit-icon.png";
import TrashIcon from "../assets/img/trash-icon.png";
import FileSaver from "file-saver";
import { jsonToCSV } from "react-papaparse";
import Loader from "components/Loader/Loader";
import ExportSelectedData from "components/ExportSelectedData";
import OrderDetailModal from "components/Modal/Order/OrderDetailModal";
import EmployeeOrderHistory from "components/Modal/EmployeeOrderHistory";
import UserPasswordModal from "components/Users/UserPasswordModal";
import { emailMsgToCreatedMember } from "utils";
import { isValidDobField } from "utils";
import moment from "moment";
import { getPhoneNo } from "utils";
import { isValidName } from "utils";
import { isValidZipCode } from "utils";
import Icon from "components/Icon";
import { getStateLength } from "utils";
import { formatNumber } from "utils";
import Notes from "components/Notes";
import NotesModal from "components/Modal/NotesModal";
import Status from "components/Status";
import TestQuantityModal from "components/Modal/TestQuantityModal";
import { downloadDataAsCSV } from "utils";
import { isValidIDNumber } from "utils";
import ClaimResubmissionModal from "components/Modal/Order/ClaimResubmissionModal";
import CreateClaimResubmissionModal from "components/Modal/Order/CreateClaimResubmissionModal";
import { phoneFormatter } from "utils";
import { RemoveSpaceAndChar } from "utils";
import MergeProfileModal from "components/Modal/MergeProfileModal";
import EligibilityDetailsModal from "components/Modal/CheckEligibility/EligibilityDetailsModal";
import MainTable from "components/Table/MainTable";
import EligibilityCheckListViewModal from "components/Modal/CheckEligibility/EligibilityCheckListViewModal";
import { draggablePersonalizationLocalStorage } from "utils";
import ViewClaimList from "./EmployeeClaims/ViewClaimList";
import { CapsFirstLetter } from "utils";

const Employees = (props) => {
  const [claimReSubmissionModal, setClaimReSubmissionModal] = useState(null);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [newUser, setNewUser] = useState(EmptyUserObj);
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [count, setCount] = useState(0);
  const [fullscreen, setFullscreen] = useState(true);
  const [title, setTitle] = useState("");
  const [assignProgram, setAssignProgram] = useState([]);
  const [assignProgramSuccessMsg, setAssignProgramSuccessMsg] = useState("");
  const [orderModal, setOrderModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [programMessage, setProgramMessage] = useState("");
  const [openErrModal, setOpenErrModal] = useState(false);
  const [openProgram, setOpenProgram] = useState(false);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [personalize, setPersonalize] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [employeesForOrder, setEmployeesForOrder] = useState(null);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [noteModal, setNoteModal] = useState(null);
  const [showMergeProfileModal, setShowMergeProfileModal] = useState(false);
  const [openTestQuantityModal, setOpenTestQuantityModal] = useState(false);
  const [eligibilityDetailsData, setEligibilityDetailsData] = useState(null);
  const [claimList, setClaimList] = useState([]);
  const [updatePatient, setUpdatePatient] = useState(null);
  const quickTools = [
    TABLE_QUICK_TOOLS.checkbox,
    !appContext.isReadOnly() && TABLE_QUICK_TOOLS.edit,
    TABLE_QUICK_TOOLS.notes,
  ];
  const [eligibilityCheckListView, setEligibilityCheckListView] = useState([]);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Gender",
    "Primary Insurance",
    "Facility",
    "Sub Agent",
    "LTCS ID",
    "Primary Insurance Number",
    "Secondary Insurance",
    "Secondary Insurance Number",
    "Test Ordered",
    "Test Available",
    "Auto Ship",
    "Updated At",
    "Eligibility",
    "Note",
    "Status",
    "Eligibility Status",
  ];

  const altaSearchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Gender",
    "Primary Insurance",
    "LTCS ID",
    "Primary Insurance Number",
    "Secondary Insurance",
    "Secondary Insurance Number",
    "Updated At",
    "Eligibility",
    "Note",
    "Status",
    "Blank",
  ];

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      const filterTerm = location.state.term;
      const filterTermName = location.state.name;
      if (filterTerm && filterTermName) {
        setFilter({ ...filter, [filterTerm]: filterTermName });
        setTimeout(() => setShowFilter(false), 1);
      }
    }
  }, [location.state]);

  const handleUpdateInsurance = async () => {
    const arr = INSURANCE_PROVIDER.filter(
      (f) => f.label.toLowerCase().includes("medicare") && f.payerid !== "01192"
    ).map((m) => m.payerid);

    const newInsurance = INSURANCE_PROVIDER.filter((f) => !arr.includes(f.value));
    console.log("newInS", arr);
    return;
    await api.addJSON(newInsurance);
  };

  useEffect(() => {
    const sortLS = sortingFilterInLC.get();
    if (sortLS.employees && sortLS.employees.sortBy) {
      setSortBy(sortLS.employees.sortBy);
      setSortDescending(sortLS.employees.sortDescending);
    }
    // if (sortLS.employees && sortLS.employees.filter) {
    //   setFilter(sortLS.employees.filter);
    // }
    setPersonalize(
      draggablePersonalizationLocalStorage.get(
        appContext?.user,
        "member",
        CONFIG.isLabType ? LAB_MEMBER_PERSONALIZE : NEW_PERSONALIZE
      )
    );
  }, [appContext?.company]);

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const filterUsers = (searchTerm, searchInput) => {
    return setFilteredUsers(users.filter((user) => user[searchTerm].includes(searchInput)));
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        if (key === "updatedAt") {
          return (
            new Date(obj.updatedAt) > filters[key].startDate._d && new Date(obj.updatedAt) < filters[key].endDate._d
          );
        }
        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "scheduleID") {
          return obj.scheduleID && (obj.scheduleID === filters[key] || obj.scheduleLinked.indexOf(filters[key]) !== -1);
        }
        if (key === "department") {
          return obj.department && obj.department === filters[key];
        }
        if (key === "isSchedule") {
          return (obj.isSchedule ? obj.isSchedule : 0) == filters[key];
        }
        if (key === "isVaccinated") {
          return (obj.isVaccinated ? 1 : 0).toString() === filter[key];
        }
        if (key === "qaDone") {
          return obj.qaDone === filters[key];
        }
        if (key === "autoShipment") {
          return obj.autoShipment == filters[key];
        }
        if (key === "testDone") {
          return obj.testDone === filter[key];
        }
        if (key === "note") {
          if (filters[key] === "1" && obj.note) return true;
          if (filters[key] === "0") return true;
        }
        if (key === "eligibilityStatus") {
          const val = obj[key]?.message === "Eligibile" ? "eligibile" : obj[key] ? "invalid" : "not checked";
          return val && val.toString() === filters[key];
        }
        if (key === "testOrdered" || key === "testAvailable") {
          return obj[key].toString() === filters[key];
        }
        if (key === "empTZ") {
          return formatTimeZone(obj[key]).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "checkIn") {
          return obj.checkIn === filter[key];
          // return new Date(obj.checkIn) > filters[key].startDate._d && new Date(obj.checkIn) < filters[key].endDate._d;
        }
        if (key === "payerId") {
          return filter[key].includes(obj.insuranceCompany);
        }
        if (key === "secondaryInsurance") {
          return filter[key].includes(obj.secondaryInsurance);
        }
        if ((key === "programName" || key === "zoneColor") && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }
        if (key === "status") {
          return filter[key] === obj.status;
        }
        if (key === "Blank" && filter[key].length > 0) {
          return filter[key].some((f) => !obj[PARSE_FILTER_KEY_NAME[f]]);
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
    if (e.target.checked && user.scheduleID) {
      setAssignProgram((a) =>
        a.length > 0 ? (a[0].scheduleID === user.scheduleID ? [...a, user] : [...a]) : [...a, user]
      );
    } else {
      setAssignProgram(assignProgram.filter((f) => f.id !== user.id));
    }
  };
  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredUsers.map((t) => t.id) : []);
    const values = val ? filteredUsers.filter((f) => f.isSchedule === 2 && f) : [];
    let assignVal = [];
    if (values.length > 0) {
      const reduceVal = values.reduce((result, value) => {
        if (value.scheduleLinked && value.scheduleLinked.length > 0) {
          result[value.scheduleLinked] = (result[value.scheduleLinked] || 0) + 1;
        }
        return result;
      }, {});

      const maxVal = Object.keys(reduceVal).reduce((a, b) => (reduceVal[a] > reduceVal[b] ? a : b));
      const arrVal = maxVal.split(",");
      assignVal = values.filter((f) => JSON.stringify(f.scheduleLinked) === JSON.stringify(arrVal));
    }
    setAssignProgram(assignVal);
  };
  const sendEmailToMember = async (newUser) => {
    try {
      const message = emailMsgToCreatedMember(newUser.firstName, newUser.medicalNo, newUser.password);

      await api.sendEmail([{ email: newUser.email, subject: "SafeCamp LTC Team", msg: message }]);
    } catch (error) {
      console.log("ERROR createUser: ", error);
    }
  };

  const replaceInsurance = async () => {
    const members = filteredUsers.filter((f) => f.insuranceCompany !== "01192" && f.medicareNo);

    const updateMembers = members.map((m) => {
      return {
        ...m,
        insuranceCompany: "01192",
        insuranceCompanyCode: "Medicare",
        medicalNo: m.medicareNo,
        insuranceDetails: {
          insuranceCompany: m.insuranceCompany,
          insuranceCompanyCode: m.insuranceCompanyCode,
          insuranceGroupId: m.insuranceGroupId,
          medicalNo: m.medicalNo,
        },
      };
    });

    if (updateMembers.length > 0) {
      await api.updateMember(updateMembers);
    }
  };

  const createMember = async (user) => {
    try {
      setLoading(true);
      const phoneNo = setPhoneNo(user.phoneNumber);

      if (user.isNew) {
        const result = await api.newEmployee(user, null, appContext.user);
        if (result && user.password) {
          await api.createSystemUser(user, result);
          await sendEmailToMember(user);
        }

        appContext.showSuccessMessage(t("clientCreatedSuccessfully"));
      } else {
        let refProvider = null;
        let ordProvider = null;
        let provider = null;
        if (user.referringProvider) {
          refProvider = appContext.providers.find((f) => f.id === user.referringProvider);
        }
        if (user.orderingProvider) {
          ordProvider = appContext.providers.find((f) => f.id === user.orderingProvider);
        }
        if (user.renderingProvider) {
          provider = appContext.providers.find((f) => f.id === user.renderingProvider);
        }
        const result = await api.updateEmployee(user, appContext.user);

        if (appContext.user?.phone_number === usersNo.ibrahim || CONFIG.isLabType) {
          const claims = await api.fetchPatientClaim(user.id);
          setUpdatePatient({ user, provider, refProvider, ordProvider });
          setClaimList(
            claims.filter(
              (f) =>
                f.status === claimSubmitStatus.denied ||
                f.status === claimSubmitStatus.rejected ||
                f.status === claimSubmitStatus.draft
            )
          );
          // await api.updateClaims(user, appContext.user, provider, refProvider, ordProvider);
        }
        if (result && user.password) {
          await api.createSystemUser(user, result);
          await sendEmailToMember(user);
        }
        if (!user.onAlert) appContext.showSuccessMessage(t("clientUpdatedSuccessfully"));
      }
      await appContext.resetEmployees();
    } catch (error) {
      console.log("ERROR:User-", error);
      appContext.showErrorMessage(error.message);
      setOpenCreator(true);
    }
  };

  const handleImportLabMember = async (empData, subAgentID) => {
    if (empData.length === 0) {
      appContext.showErrorMessage(t("emptyFile"));
      setOpenImportModal(false);
      return;
    }

    if (!subAgentID) {
      appContext.showErrorMessage("Sub Agent was not selected");
      return;
    }

    const client = await api.getSubAgentById(subAgentID);
    const companyObj = await api.getCompanySetting();
    let allEmployess = companyObj.employeeCounter;
    const programName = [];
    const arr = [];
    const arrdata = [];
    const empList = [...users];
    const newEmpIds = [];
    const ttlLength = empData.length;
    // const filterheader = EMPLOYEE_HEADERS.every((c) => Object.keys(empData[0].data).includes(c));
    // if (!filterheader) {
    //   appContext.showErrorMessage("data not matched");
    //   setOpenImportModal(false);
    //   return;
    // }
    setOpenImportModal(false);
    setLoading(true);

    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data, countryCode: "" };
      const splitName = emp.name.split(",");
      if (!emp.name && !emp.dob) continue;
      emp.firstName = getValidDep(splitName[1] || "").trim();
      emp.lastName = getValidDep(splitName[0] || "").trim();
      emp.phoneNumber = phoneFormatter(emp.phoneNumber || "");
      emp.companyID = client.locationID;
      emp.clientID = client.clientID;
      const iso = countryListAllIsoData.find((f) => f.name === emp.country) || {
        code: "US",
      };
      if (emp.insuranceCompany === "#N/A") {
        emp.insuranceCompany = "";
      }
      emp.isoCode = iso.code;
      emp.subAgentID = subAgentID;
      emp.subAgent = { ...client, label: client.name, value: client.id };
      emp.testAvailable = client.testLimit;
      emp.testOrdered = parseInt(emp.Total);
      emp.medicalNo = RemoveSpaceAndChar(emp.medicalNo)?.toUpperCase();
      emp.insuranceGroupId = emp.groupId?.trim().toUpperCase();
      emp.insuranceCompany = emp.insuranceCompany ? emp.insuranceCompany.padStart(5, 0) : "";
      emp.email = emp.email?.trim().toLowerCase().replace(/\s/g, "") || "";
      emp.city = emp.city?.trim() || "";
      emp.localNo = emp.accountNo?.trim() || "";
      emp.autoShipment = (emp["Automatic Monthly"] || "No") === "Yes";
      if (emp.address) {
        emp.street = emp.address;
      }

      if (emp.address2) {
        emp.street2 = emp.address2;
      }
      if (emp.gender) {
        emp.gender = getValidGender(emp.gender);
      }
      if (emp.providerCode) {
        emp.renderingProvider = appContext.providers.find((f) => f.code === emp.providerCode).id;
      }
      emp.dob = moment(emp.dob, "MM/DD/YYYY").format("YYYY-MM-DD");
      if (emp.insuranceCompany === "01192") {
        emp.medicareNo = emp.medicalNo;
      }
      if (!emp.firstName?.trim()) {
        arr.push({
          message: `First Name Required ${index + 2}.`,
          data: emp,
        });

        continue;
      }
      if (!emp.insuranceCompanyCode) {
        emp.insuranceCompanyCode = "";
      }
      if (!isValidName(emp.firstName)) {
        arr.push({
          message: `First Name is invalid must contains at least 2 letters and Name should be realistic ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (!emp.lastName?.trim()) {
        arr.push({
          message: `Last Name Required ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (!isValidName(emp.lastName)) {
        arr.push({
          message: `Last Name is invalid must contains at least 2 letters and Name should be realistic ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      // if (!emp.insuranceCompany) {
      //   arr.push({
      //     message: `primary insurance name is Required`,
      //     data: emp,
      //   });
      //   continue;
      // }

      // const findVal = INSURANCE_PROVIDER.find((f) => f.value === emp.insuranceCompany.replace(/\s/g, ""));
      // if (!findVal) {
      //   arr.push({
      //     message: `insurance provider is Invalid on row ${index + 2}.`,
      //     data: emp,
      //   });
      // } else {
      //   emp.insuranceCompany = findVal.payerid;
      //   emp.medicareAgent = findVal.label;
      //   emp.insuranceCompanyCode = findVal.label;
      //   if (findVal.payerid === "01192") {
      //     emp.medicalNo = emp.medicalNo;
      //     emp.medicareNo = emp.medicalNo;
      //   }
      // }
      if (!emp.dob) {
        arr.push({
          message: `Date of Birth is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (!emp.phoneNumber || !emp.phoneNumber.trim()) {
        arr.push({
          message: `phone number is required on row ${index + 2}`,
          data: emp,
        });
        continue;
      }
      if (emp.phoneNumber && !validatePhone(emp.phoneNumber)) {
        arr.push({
          message: `phone number is not correct ${emp.phone} on row ${index + 2}`,
          data: emp,
        });
        continue;
      }

      if (emp.email && !isValidEmail(emp.email)) {
        arr.push({
          message: `Invalid email ${emp.email} on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (emp.state.length > getStateLength(emp)) {
        arr.push({
          message: `State must be 2 characters on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      const isRecordExists = users.find((f) => f.schrID === emp.schrID);
      if (isRecordExists) {
        // arr.push({
        //   message: `${t("clientAlreadyExistOnRow")} ${index + 2}.`,
        //   data: emp,
        // });
        continue;
      }

      allEmployess = allEmployess + 1;
      console.log("Employee", emp);
      const resultID = await api.newEmployee(emp, allEmployess);
      if (resultID) newEmpIds.push(resultID.id);

      arrdata.push("Success");
    }

    if (arrdata.length == 0 && arr.length === 0) {
      appContext.showErrorMessage(t("emptyFile"));
      setLoading(false);
      return;
    }

    await api.updateCompnayEmployeeNo(allEmployess);
    await appContext.resetEmployees();
    setLoading(false);
    if (arr.length > 0 || arrdata.length > 0) {
      setErrorData(arr);
      setTitle(t("client"));
      setCount(allEmployess);
      setsuccessData(arrdata);
      setOpenErrModal(true);
    }
  };

  const handleClose = () => {
    setOpenCreator(false);
    setOpenConfirmation({ isShow: false });
  };

  const getDuplicateData = async () => {
    const emps = filteredUsers.filter(
      (f, i, arr) =>
        arr.findIndex((m) => m.phoneNumber === f.phoneNumber && f.dob === m.dob && f.medicalNo === m.medicalNo) !== i
    );
    const duplicateData = filteredUsers.filter(
      (f, i) =>
        emps.findIndex((m) => m.phoneNumber === f.phoneNumber && f.dob === m.dob && f.medicalNo === m.medicalNo) !== -1
    );
    console.log("duplicateData", duplicateData);
    await downloadDataAsCSV(duplicateData, "patientDuplicate.csv");
  };

  const updateSubId = async () => {
    filteredUsers.map(async (emp) => {
      let searchFilter = `preferred_username ^=\  "${emp.loginID}\"`;
      const employees = await api.fetchAllUsers(searchFilter);
      if (employees.Users.length > 0) {
        await api.updateSignUp(emp);
        console.log("employees", employees);
      }
    });
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.employees = { sortBy, sortDescending, filter, timeFilter };
    sortingFilterInLC.save(pervSortLS);
  };

  const currentUsers = (users) => {
    const indexOfLastUser = currentPage * PAGE_LIMIT;
    const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredUsers);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(users);
  useEffect(() => {
    formatEmployees();
    setLoading(false);
  }, [appContext?.employees, appContext?.subAgents, appContext?.providers]);

  useEffect(() => {
    handleSaveFilter();
    if (filteredUsers.length === 0) return;
    changeSort(sortBy, sortDescending, filteredUsers, setFilteredUsers);
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(sortList(sortBy, sortDescending, nestedFilter(users, filter)));
      setCurrentPage(1);
    }
  }, [filter]);

  const formatEmployees = () => {
    const emps = formatEmployeesData(
      appContext.employees,
      appContext.subAgents || [],
      appContext.locations || [],
      appContext.companies || [],
      appContext.providers || []
    );
    setUsers(emps);
    if (sortBy) {
      setFilteredUsers(sortList(sortBy, sortDescending, nestedFilter(emps, filter)));
    } else {
      setFilteredUsers(nestedFilter(emps, filter));
    }
  };

  if (filteredUsers.length > 0) {
    pageNumbers = getPageNumbers(filteredUsers);
  }

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
    handleChangePage(1);
  };

  const handlePasswordClose = () => {
    setOpenPasswordModal(false);
  };

  const handleResetPassword = (obj) => {
    console.log("Password Obj", obj);
  };

  const handleEditUser = (user) => {
    const obj = { ...user, isNew: false, username: user.loginID };
    setNewUser(obj);
    setOpenCreator(true);
  };

  const onDeleteConfirm = async () => {
    let ttl = checkboxes.length;
    if (userToDelete || ttl > 0) {
      try {
        setLoading(true);
        let deleted = [];
        if (userToDelete) {
          const res = await api.deleteEmployee(userToDelete.id, appContext.user);
          if (res) {
            deleted.push(res);
          }
          if (userToDelete.loginID) await api.deleteUser(userToDelete.loginID);
        } else if (ttl > 0) {
          for (let i = 0; i < ttl; i++) {
            const emp = users.find((f) => f.id === checkboxes[i]);
            const res = await api.deleteEmployee(checkboxes[i], appContext.user);
            if (res) {
              deleted.push(res);
            }
            if (emp.loginID) await api.deleteUser(emp.loginID);
          }
        }
        if (deleted.length > 0) {
          appContext.showSuccessMessage(`${t("clientDeletedSuccessfully")}${deleted > 1 ? "s" : ""}`);
        } else {
          appContext.showErrorMessage(`${t("clientHaveClaims")}`);
        }

        setUserToDelete(null);
        setCheckboxes([]);
        setAssignProgram([]);
        setAllSelected(false);
        appContext.resetEmployees();
      } catch (error) {
        console.log("Error:-", error.message);
        appContext.showErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  const formatDOB = (dob) => {
    if (!dob) return "";
    const arr = dob.split("/");
    return `${arr[0].padStart(2, 0)}/${arr[1].padStart(2, 0)}/${arr[2]}`;
  };

  const parseDateOfBirth = (dob) => {
    if (!dob) return "";
    const arr = dob.split("/");
    return `${arr[2]}-${arr[0].padStart(2, 0)}-${arr[1].padStart(2, 0)}`;
  };

  const handleImport = async (empData, subAgentID) => {
    if (empData.length === 0) {
      appContext.showErrorMessage(t("emptyFile"));
      setOpenImportModal(false);
      return;
    }

    if (!subAgentID) {
      appContext.showErrorMessage("Sub Agent was not selected");
      return;
    }

    const client = await api.getSubAgentById(subAgentID);
    const companyObj = await api.getCompanySetting();
    let allEmployess = companyObj.employeeCounter;
    const programName = [];
    const arr = [];
    const arrdata = [];
    const empList = [...users];
    const newEmpIds = [];
    const ttlLength = empData.length;
    const filterheader = EMPLOYEE_HEADERS.every((c) => Object.keys(empData[0].data).includes(c));
    if (!filterheader) {
      appContext.showErrorMessage("data not matched");
      setOpenImportModal(false);
      return;
    }
    setOpenImportModal(false);
    setLoading(true);

    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data, countryCode: "" };
      if (!emp.firstName && !emp.lastName && !emp.phone && !emp.email) continue;

      emp.firstName = getValidDep(emp.firstName || "");
      emp.lastName = getValidDep(emp.lastName || "");
      emp.phoneNumber = getPhoneNumber(emp.phoneNumber || "");
      emp.companyID = client.locationID;
      emp.clientID = client.clientID;
      const iso = countryListAllIsoData.find((f) => f.name === emp.country) || {
        code: "US",
      };
      emp.isoCode = iso.code;
      emp.subAgentID = subAgentID;
      emp.subAgent = { ...client, label: client.name, value: client.id };
      emp.testAvailable = client.testLimit;
      emp.testOrdered = parseInt(emp.Total);
      emp.medicalNo = emp.primaryInsuranceNo?.trim().toUpperCase();
      emp.insuranceGroupId = emp.groupId?.trim().toUpperCase();
      emp.autoShipment = (emp["Automatic Monthly"] || "No") === "Yes";
      if (emp.address) {
        emp.street = emp.address;
      }
      if (emp.address2) {
        emp.street2 = emp.address2;
      }
      if (emp.gender) {
        emp.gender = getValidGender(emp.gender);
      }

      const dob = moment(emp.dob, "MM/DD/YYYY").format("MM/DD/YYYY");
      emp.dob = moment(emp.dob, "MM/DD/YYYY").format("YYYY-MM-DD");

      if (!emp.firstName?.trim()) {
        arr.push({
          message: `First Name Required ${index + 2}.`,
          data: emp,
        });

        continue;
      }
      if (!isValidName(emp.firstName)) {
        arr.push({
          message: `First Name is invalid must contains at least 2 letters and Name should be realistic ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (!emp.lastName?.trim()) {
        arr.push({
          message: `Last Name Required ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (!isValidName(emp.lastName)) {
        arr.push({
          message: `Last Name is invalid must contains at least 2 letters and Name should be realistic ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (!emp.primaryInsuranceName) {
        arr.push({
          message: `primary insurance name is Required`,
          data: emp,
        });
        continue;
      }
      if (!emp.primaryInsuranceNo) {
        arr.push({
          message: `primary insurance number is Required`,
          data: emp,
        });
        continue;
      }

      if (!isValidIDNumber(emp.primaryInsuranceNo)) {
        arr.push({
          message: `primary insurance number is invalid`,
          data: emp,
        });
        continue;
      }

      if (emp.primaryInsuranceNo) {
        emp.medicalNo = emp.primaryInsuranceNo;
      }
      if (emp.primaryAdvantageMedicareNo) {
        emp.medicareNo = emp.primaryAdvantageMedicareNo;
      }
      if (emp?.primaryGroupId) {
        emp["insuranceGroupId"] = emp.primaryGroupId;
      }
      const findVal = INSURANCE_PROVIDER.find(
        (f) => f.label.toLowerCase().replace(/\s/g, "") === emp.primaryInsuranceName.toLowerCase().replace(/\s/g, "")
      );
      if (!findVal) {
        arr.push({
          message: `insurance provider is Invalid on row ${index + 2}.`,
          data: emp,
        });

        continue;
      } else {
        emp.insuranceCompany = findVal.payerid;
        emp.medicareAgent = findVal.label;
        emp.insuranceCompanyCode = findVal.label;
        if (findVal.payerid === "01192") {
          emp.medicalNo = emp.primaryInsuranceNo;
          emp.medicareNo = emp.primaryInsuranceNo;
        }
      }
      if (!emp.dob) {
        arr.push({
          message: `Date of Birth is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      if (
        emp.dob &&
        (!isValidDobField(dob, "MM/DD/YYYY") || !moment(dob, "MM/DD/YYYY").isBetween("01/01/1900", currentDate()))
      ) {
        arr.push({
          message: `Invalid (${dob}) Date of Birth format is (MM/DD/YYYY)  on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (!emp.gender) {
        arr.push({
          message: `Gender is required on row ${index + 2}`,
          data: emp,
        });
        continue;
      }

      if (!emp.primaryAdvantageMedicareNo) {
        arr.push({
          message: `Medicare ID is Required`,
          data: emp,
        });
        continue;
      }
      if (emp.secondaryInsuranceName && emp.secondaryInsuranceNo) {
        emp["insuranceDetails"] = {};
        if (emp.secondaryInsuranceName.toLowerCase() === primaryInsuranceName.toLowerCase()) {
          delete emp["insuranceDetails"];
        } else {
          if (emp.secondaryGroupId) {
            emp["insuranceDetails"]["insuranceGroupId"] = emp.secodaryInsuranceNo;
          }
          emp["insuranceDetails"]["medicalNo"] = emp.secondaryInsuranceNo;

          const findVal = INSURANCE_PROVIDER.find(
            (f) =>
              f.label.toLowerCase().replace(/\s/g, "") === emp.secondaryInsuranceName.toLowerCase().replace(/\s/g, "")
          );
          if (!findVal) {
            arr.push({
              message: `insurance provider is Invalid on row ${index + 2}.`,
              data: emp,
            });

            continue;
          } else {
            emp["insuranceDetails"]["insuranceCompany"] = findVal.payerid;
            emp["insuranceDetails"]["insuranceCompanyCode"] = findVal.label;
          }
        }
      }

      // if (!emp.medicareAgent) {
      //   arr.push({
      //     message: `insurance provider is Required on row ${index + 2}.`,
      //     data: emp,
      //   });
      //   continue;
      // }
      if (!emp.phoneNumber || !emp.phoneNumber.trim()) {
        arr.push({
          message: `phone number is required on row ${index + 2}`,
          data: emp,
        });
        continue;
      }
      if (emp.phoneNumber && !validatePhone(emp.phoneNumber)) {
        arr.push({
          message: `phone number is not correct ${emp.phone} on row ${index + 2}`,
          data: emp,
        });
        continue;
      }
      if (!emp.email || !emp.email.trim()) {
        arr.push({
          message: `email is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (emp.email && !isValidEmail(emp.email)) {
        arr.push({
          message: `Invalid email ${emp.email} on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      if (!emp.street || !emp.street.trim()) {
        arr.push({
          message: `Address is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      if (!emp.city || !emp.city.trim()) {
        arr.push({
          message: `City is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (!emp.state || !emp.state.trim()) {
        arr.push({
          message: `State is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (emp.state.length > getStateLength(emp)) {
        arr.push({
          message: `State must be 2 characters on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (!emp.zip || !emp.zip.trim()) {
        arr.push({
          message: `Zip Code is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (!isValidZipCode(emp.isoCode || "US", emp.zip)) {
        arr.push({
          message: `Zip Code is invalid on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      const isRecordExists = await api.getUserEmployeeID(emp);
      if (isRecordExists) {
        arr.push({
          message: `${t("clientAlreadyExistOnRow")} ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      allEmployess = allEmployess + 1;

      const resultID = await api.newEmployee(emp, allEmployess);
      if (resultID) newEmpIds.push(resultID.id);

      arrdata.push("Success");
    }

    if (arrdata.length == 0 && arr.length === 0) {
      appContext.showErrorMessage(t("emptyFile"));
      setLoading(false);
      return;
    }

    await api.updateCompnayEmployeeNo(allEmployess);
    await appContext.resetEmployees();
    setLoading(false);
    if (arr.length > 0 || arrdata.length > 0) {
      setErrorData(arr);
      setTitle(t("client"));
      setCount(allEmployess);
      setsuccessData(arrdata);
      setOpenErrModal(true);
    }
  };

  const handleImportEmployee = async (empData) => {
    if (empData.length === 0) return;

    const ttlLength = empData.length;
    const likeIds = [];
    const ids = [];
    let i = 0;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      if (data.name) {
        const arr = data.name.split(" ");

        const firstName = arr[0].toLowerCase().trim();
        const lastName = (arr[1] || "").trim().toLowerCase();

        const emp = appContext.employees.filter(
          (e) =>
            (firstName.indexOf(e.firstName.toLowerCase()) !== -1 &&
              lastName.indexOf(e.lastName.toLowerCase()) !== -1) ||
            (e.firstName.toLowerCase().indexOf(firstName) !== -1 && e.lastName.toLowerCase().indexOf(lastName) !== -1)
        );
        if (emp.length === 0) {
          ids.push({
            firstName: firstName,
            lastName: lastName,
            hrFirst: "",
            hrLast: "",
            zone: data.programName,
          });
        } else {
          likeIds.push({
            id: emp[0].id,
            firstName: firstName,
            lastName: lastName,
            hrFirst: emp[0].firstName,
            hrLast: emp[0].lastName,
            zone: data.programName,
          });
        }
      }
    }

    likeIds.forEach(async (e) => {
      await api.updateEmployeeDefault(e.id, e.zone);
    });

    const content = jsonToCSV(ids);
    const fileContent = new Blob([content], { type: "csv" });
    // await FileSaver.saveAs(fileContent, "houston_zone_22_07_22.csv");
  };
  const handleImportEmployeeOld = async (empData) => {
    if (empData.length === 0) return;
    const ttlLength = empData.length;
    const likeIds = [];
    const ids = [];
    let i = 0;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      if (data.firstName) {
        const firstName = data.firstName.toLowerCase();
        const lastName = data.lastName.toLowerCase();
        const firstLike = data.firstName.toLowerCase().substring(0, 2);
        const lastLike = data.lastName.toLowerCase().substring(0, 2);
        const emp = appContext.employees.filter(
          (e) =>
            (firstName.indexOf(e.firstName.toLowerCase()) !== -1 &&
              lastName.indexOf(e.lastName.toLowerCase()) !== -1) ||
            (e.firstName.toLowerCase().indexOf(firstName) !== -1 && e.lastName.toLowerCase().indexOf(lastName) !== -1)
        );
        if (emp.length === 0) {
          // ids.push({ firstName, lastName });
          const emp1 = appContext.employees.filter(
            (e) =>
              (firstLike === e.firstName.toLowerCase().substring(0, 2) &&
                lastName.indexOf(e.lastName.toLowerCase()) !== -1) ||
              (firstName.indexOf(e.firstName.toLowerCase()) !== -1 &&
                lastLike === e.lastName.toLowerCase().substring(0, 2))
          );
          if (emp1.length === 0) {
            likeIds.push({
              firstName: data.firstName,
              lastName: data.lastName,
              hrFirst: "",
              hrLast: "",
            });
          } else {
            likeIds.push({
              firstName: data.firstName,
              lastName: data.lastName,
              hrFirst: emp1[0].firstName,
              hrLast: emp1[0].lastName,
            });
          }
        } else {
          likeIds.push({
            firstName: data.firstName,
            lastName: data.lastName,
            hrFirst: emp[0].firstName,
            hrLast: emp[0].lastName,
          });
        }
      }
    }

    const content = jsonToCSV(likeIds);
    const fileContent = new Blob([content], { type: "csv" });
    //  await FileSaver.saveAs(fileContent, "houston_data_exists_20_07_22.csv");
  };

  const onHandleConfirmation = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      switch (type) {
        case CONFIRMATION_TYPE.DELETE:
          setCheckboxes([]);
          setAllSelected(false);
          setAssignProgram([]);
          setUserToDelete(null);
          break;
      }
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DELETE:
        onDeleteConfirm();
        break;
    }
  };

  const handleCreateClaim = async () => {
    if (checkboxes.length === 1) {
      let res = filteredUsers.filter((f) => checkboxes.indexOf(f.id) !== -1);
      setClaimReSubmissionModal(res);
    } else {
      appContext.showErrorMessage(t("selectOneClientError"));
    }
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "member");
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(appContext.user.phone_number, personalisationData);
  };

  const updateTestQty = async (testQty) => {
    setOpenTestQuantityModal(false);
    try {
      setLoading(true);
      await api.updateTestAvailable(checkboxes, testQty);
      appContext.showSuccessMessage(t("clientTestAvailableUpdate", { total: checkboxes.length }));
      appContext.updateTestAvailableQty(checkboxes, testQty);
      setLoading(false);
      setCheckboxes([]);

      setAllSelected(false);
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
      appContext.showErrorMessage(err.message);
    }
  };

  const insuranceProviderFormatter = (insuranceCompany) => {
    if (!insuranceCompany) return "-";
    const item = INSURANCE_PROVIDER.find((i) => i.value === insuranceCompany);
    if (!item) return "-";
    return item?.label;
  };

  const handleEligibilityCheck = async () => {
    try {
      const empList = users.filter((e) => checkboxes.includes(e.id));
      setLoading(true);
      const [resStatus, response] = await api.checkManualBulkEligibility(empList, appContext.user);

      if (response && response.length === 1) {
        if (response[0]?.res) {
          setEligibilityDetailsData(response[0].res);
        } else {
          appContext.showErrorMessage("Failed Eligibility Check");
        }
      } else if (response && response.length > 1) {
        setEligibilityCheckListView(response);
      }

      console.log("res", resStatus);
      if (resStatus.length === 1) {
        if (resStatus.includes("Failed")) {
          appContext.showErrorMessage("Failed Eligibility Check");
        } else {
          appContext.showSuccessMessage("Passed Eligibility Check");
        }
      } else if (resStatus.length > 0 && resStatus.filter((e) => e === "Failed").length === 0) {
        appContext.showSuccessMessage("Passed Eligibility Check");
      } else if (resStatus.length > 0) {
        if (resStatus.every((e) => e === "Failed")) {
          appContext.showErrorMessage("Failed Eligibility Check");
        } else {
          appContext.showErrorMessage("Only Few Passed Eligibility Check");
        }
      }
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage("Fail to Check the Elibility");
    }
    setLoading(false);
    setCheckboxes([]);
  };
  const tdFormat = (item, row) => {
    if (item === "firstName") {
      return (
        <Link
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
          to={{
            pathname: CONFIG.isLabType ? "/admin/employeeclaim" : "/admin/orders",
            state: {
              name: row.id,
              term: "employeeID",
            },
          }}
        >
          {CapsFirstLetter(row.firstName)}
        </Link>
      );
    }
    if (item === "lastName") {
      return (
        <Link
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
          to={{
            pathname: CONFIG.isLabType ? "/admin/employeeclaim" : "/admin/orders",
            state: {
              name: row.id,
              term: "employeeID",
            },
          }}
        >
          {CapsFirstLetter(row.lastName)}
        </Link>
      );
    }
    if (item === "eligibilityStatus") {
      return (
        <div className="star-icon-wrapper">
          <Status
            type="circle"
            size="md"
            color={row[item]?.message === "Eligibile" ? "green" : row[item] ? "maroon" : "yellow"}
            crossIcon={row[item]}
            questionIcon={!row[item]}
          />
        </div>
      );
    }
    if (item === "status") {
      return (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color={row[item] === "inactive" ? "maroon" : "green"} crossIcon={row[item]} />
        </div>
      );
    }
    return row[item];
  };
  const tdFormatTitle = (item, row) => {
    if (item === "eligibilityStatus") return row[item]?.message || "Not Checked";
    return row[item];
  };

  const renderTd = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormatTitle(item.itemKey, row)}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const updateMemberStatus = async () => {
    const members = filteredUsers.filter((f) => f.eligibilityStatus && f.eligibilityStatus?.message !== "Eligibile");
  };

  const openDeleteConfirmation = (user) => {
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.DELETE,
      title: `${t("deleteClient")}`,
      message: `${t("requestClientRemoval", { total: checkboxes.length })}${checkboxes.length > 1 ? "s" : ""}?`,
    });
    if (user) setUserToDelete(user);
  };

  const handleOrderClick = () => {
    const empList = users
      .filter((e) => checkboxes.includes(e.id) && e.testAvailable > 0 && e.status === "active")
      .map((e) => {
        const minTestToOrder =
          e.subAgent?.minTestOrderQty || e.location?.minTestOrderQty || e.client?.minTestOrderQty || MIN_TEST_QUANTITY;

        return {
          ...e,
          testSendQty: e.testAvailable,
          minTestToOrder,
        };
      });
    if (empList.length === 0) {
      appContext.showErrorMessage(
        "Please select the employee having a valid available tests limit or Member Status should be active"
      );
      return;
    }
    setEmployeesForOrder(empList);
    setOrderModal(true);
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.edit:
        handleEditUser(row);
        break;

      case TABLE_QUICK_TOOLS.notes:
        !appContext.isReadOnly() && setNoteModal(row);
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {`${t("clients")} (${
                    filteredUsers ? formatNumber(filteredUsers.length) : formatNumber(users.length)
                  })`}
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={async () => {
                      if (filteredUsers && filteredUsers.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />
                  {!appContext.isReadOnly() && (
                    <>
                      <Icon
                        handleClick={() => openDeleteConfirmation()}
                        label="Delete"
                        title="Delete"
                        disabled={checkboxes.length === 0}
                        iconType={"binIcon"}
                      />

                      <Icon
                        handleClick={() => setOpenPersonalizationModal(true)}
                        title={t("personalize")}
                        label={t("personalize")}
                        iconType={"personalizeIcon"}
                      />

                      {!appContext.isReadOnly() &&
                        (appContext?.user?.phone_number === "+18888888888" || CONFIG.isLabType) && (
                          <Icon
                            handleClick={handleEligibilityCheck}
                            title={"Check Eligibility"}
                            disabled={checkboxes.length === 0}
                            iconType="acknowledgedPositiveIcon"
                            label="Eligibility"
                          />
                        )}

                      <Icon
                        handleClick={() => {
                          if (appContext.subAgents?.length === 0) {
                            appContext.showErrorMessage("First Create Sub Agent");
                            return;
                          }
                          const obj = { ...EmptyUserObj };
                          if (
                            !appContext.user.isAdmin() &&
                            !appContext.user?.isClient() &&
                            !appContext.user?.isSite()
                          ) {
                            Object.assign(obj, {
                              subAgentID: appContext.subAgents[0]?.id,
                              companyID: appContext.subAgents[0]?.locationID,
                              subAgent: appContext.subAgents[0],
                              clientID: appContext.subAgents[0]?.clientID,
                            });
                          }
                          setNewUser(obj);
                          setOpenCreator(true);
                        }}
                        label={t("createMember")}
                        title={t("createClient")}
                        iconType={"addUserIcon"}
                      />

                      <Icon
                        handleClick={() => {
                          setOpenImportModal(true);
                        }}
                        title={t("loadClientData")}
                        iconType="loadCrewIcon"
                        label={t("loadClient")}
                      />
                    </>
                  )}

                  <ExportToExcel
                    selectedItem={personalize.length > 0 && personalize.filter((m) => m.isCheck && m.itemKey)}
                    items={filteredUsers.map((e) => {
                      return {
                        ...e,
                        insuranceCompany: insuranceProviderFormatter(e.insuranceCompany),
                      };
                    })}
                    appContext={appContext}
                    filter={filter}
                    title={t("clientData")}
                    sortBy={sortBy}
                  />

                  {!appContext.isReadOnly() &&
                    (appContext?.user?.phone_number === "+18888888888" || CONFIG.isLabType) && (
                      <>
                        {!CONFIG.isLabType && (
                          <>
                            <Icon
                              handleClick={handleOrderClick}
                              title={t("orderTest")}
                              label={t("orderTest")}
                              iconType="assignZoneIcon"
                              disabled={
                                usersToMap.filter((user) => checkboxes.includes(user.id) && user.testAvailable > 0)
                                  .length <= 0
                              }
                            />

                            <Icon
                              handleClick={() => setOpenTestQuantityModal(true)}
                              title={"Update Test Available"}
                              disabled={checkboxes.length === 0}
                              iconType="assignZoneIcon"
                              label="UTA"
                            />
                          </>
                        )}

                        {CONFIG.isLabType && (
                          <Icon
                            title={t("createClaim")}
                            label={t("createClaim")}
                            iconType={"acknowledgedPositiveIcon"}
                            handleClick={() => {
                              handleCreateClaim();
                            }}
                          />
                        )}
                        {/* <Icon
                          handleClick={() => setShowMergeProfileModal(true)}
                          title={"Combine Profile"}
                          label="Combine"
                          iconType="assignZoneIcon"
                          disabled={checkboxes.length < 2}
                        /> */}
                      </>
                    )}
                </div>

                {showFilter && (
                  <Filter
                    grouped
                    filterTerms={CONFIG.isLabType ? altaSearchTerms : searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                    member
                  />
                )}

                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  cssClass={filteredUsers.length === 0 && "overFlow-y-hidden"}
                  columns={personalize}
                  rows={usersToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={"member"}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={130}
                  selectedRows={checkboxes}
                  tools={quickTools}
                  dropDownOptions={PATIENTS_DROPDOWN_OPTIONS}
                  handleDropDownClick={(type, row) => {
                    if (type === "logs") {
                      setSelectedEmployee(row);
                    }
                  }}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      {showAlert && (
        <SweetAlert
          btnSize="md"
          info
          onConfirm={() => {
            setShowAlert(false);
            setAssignProgramSuccessMsg("");
          }}
        >
          {t("scheduleNotAssigned")}
        </SweetAlert>
      )}
      {openCreator && (
        <EmployeeModal
          user={{
            ...newUser,
            ...(appContext?.userSubAgent && {
              subAgentID: appContext.userSubAgent.id,
              companyID: appContext.userSubAgent.locationID,
              clientID: appContext.userSubAgent.clientID,
            }),
          }}
          handleClose={handleClose}
          handleSave={createMember}
          resetCall={setOpenPasswordModal}
          users={users}
        />
      )}
      {noteModal && (
        <NotesModal
          handleClose={(obj) => {
            if (obj) {
              obj.note
                ? appContext.showSuccessMessage("add Notes Successfully")
                : appContext.showSuccessMessage("Remove Notes Successfully");
              appContext.resetEmployees();
            }
            setNoteModal(null);
          }}
          emp
          user={appContext.user}
          data={noteModal}
        />
      )}
      {openPasswordModal && (
        <UserPasswordModal user={newUser} handleClose={handlePasswordClose} handleSave={handleResetPassword} />
      )}

      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={onHandleConfirmation}
          closeBtn
        />
      )}

      {openImportModal && (
        <EmployeeImportModal
          handleClose={() => setOpenImportModal(false)}
          handleImport={CONFIG.isLabType ? handleImportLabMember : handleImport}
          appContext={appContext}
          title={t("importClientDataTitle")}
        />
      )}

      {openErrModal && (
        <ImportErrorModal
          member
          title={title}
          errData={errorData}
          count={count}
          successData={successData}
          handleClose={async () => {
            setOpenErrModal(false);
            setErrorData([]);
            setsuccessData([]);
            setTitle("");
            await appContext.resetEmployees();
          }}
        />
      )}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {selectedEmployee && (
        <EmployeeOrderHistory user={selectedEmployee} handleClose={() => setSelectedEmployee(null)} />
      )}
      {openTestQuantityModal && (
        <TestQuantityModal handleConfirm={updateTestQty} handleClose={() => setOpenTestQuantityModal(false)} />
      )}
      {orderModal && (
        <OrderDetailModal
          handleClose={(isProcess, limitExccedEmployees) => {
            setOrderModal(false);
            setCheckboxes([]);
            setAllSelected(false);
            if (isProcess) {
              if (limitExccedEmployees && limitExccedEmployees.length > 0) {
                appContext.showSuccessMessage(
                  `${limitExccedEmployees.length} order request not processed because of limit excceed`
                );
              } else {
                appContext.showSuccessMessage("Order created Successfully");
              }
              appContext.resetEmployees();
            }
          }}
          employees={employeesForOrder}
          appContext={appContext}
        />
      )}
      {showMergeProfileModal && (
        <MergeProfileModal
          handleClose={(val) => {
            setShowMergeProfileModal(false);
            if (val) {
              appContext.showSuccessMessage("Successfully Combine Profiles");
              setCheckboxes([]);
              appContext.resetEmployees();
            }
          }}
          employees={filteredUsers.filter((f) => checkboxes.includes(f.id))}
          userName={appContext.user.name}
        />
      )}

      {eligibilityDetailsData && (
        <EligibilityDetailsModal handleClose={() => setEligibilityDetailsData(null)} data={eligibilityDetailsData} />
      )}

      {eligibilityCheckListView.length > 0 && (
        <EligibilityCheckListViewModal
          handleClose={() => setEligibilityCheckListView([])}
          lists={eligibilityCheckListView}
          setEligibilityDetailsData={setEligibilityDetailsData}
        />
      )}

      {claimList && claimList.length > 0 && (
        <ViewClaimList
          empClaimsList={claimList}
          patient={updatePatient}
          handleClose={(val) => {
            if (val) {
              appContext.showSuccessMessage("Update Claims Successfully");
            }
            setClaimList([]);
          }}
          cssClass={"seperateModal"}
        />
      )}

      {claimReSubmissionModal && (
        <CreateClaimResubmissionModal
          // orders={filteredOrders.filter((f) => checkboxes.indexOf(f.id) !== -1)}
          showPrevClaims
          user={claimReSubmissionModal}
          handleClose={(val) => {
            setClaimReSubmissionModal(null);
            setCheckboxes([]);
          }}
          fileDir="claim"
        />
      )}
    </div>
  );
};

export default Employees;
