import React, { useState, useContext, useEffect, useMemo } from "react";
import { Button, Modal, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { HEALTH_AND_SAFTEY, newRoleOptions } from "constant";
import API from "api";
import { AppContext } from "context/app-context";
import { phoneFormatter, validatePhone, toTitleCase, parseBooleanValue } from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
import ProviderSearchRecord from "components/ProviderSearchRecord";
import { DEFAULT_PROVIDERS } from "constant";
import { PARSE_PROVIDER } from "constant";
import { DEFAULT_PROVIDERS_ARR } from "constant";

const ProviderModal = (props) => {
  const { show, user, handleSave, handleClose, setting } = props;

  const appContext = useContext(AppContext);
  const [newUser, setNewUser] = useState(user);
  const [error, setError] = useState("");
  const [defaultSetting, setDefaultSetting] = useState(user.isNew ? {} : setting.defaultSetting);
  const [phoneError, setPhoneError] = useState("");
  const [recordData, setRecordData] = useState([]);
  const [selectSearchData, setSelectSearchData] = useState(null);

  useEffect(() => {
    setNewUser(user);
  }, [user]);

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };

  const populate = (record) => {
    setSelectSearchData(null);
    const obj = {
      ...newUser,
      ...record,
      firstName: record.firstName,
      lastName: record.firstName,
      isNew: true,
      country: record.country ? record.country : "United States",
      idNumber: record.id_number,
    };
    setNewUser(obj);
  };
  const searchProvider = async (provider) => {
    let record = [];
    try {
      record = await API.getProviderPublicAPI(provider);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }

    if (record && record.length === 1) {
      populate(record[0]);
    }
    if (record && record.length > 1) {
      setRecordData(record);
    }
  };

  const onHandleSave = () => {
    let roles = [];
    const objToSave = { ...newUser };
    if (!newUser.firstName || !newUser.firstName.trim()) {
      setError("First Name is required");
      return;
    }

    Object.assign(objToSave, {
      name: `${newUser.firstName.trim()} ${newUser?.middleName.trim()} ${newUser?.lastName.trim()}`,
    });

    if (!newUser.npi || !newUser.npi.trim()) {
      setError("NPI is required");
      return;
    }

    let provDefaultSetting = defaultSetting;
    if (!newUser.isNew && setting.defaultSetting && Object.keys(setting.defaultSetting).length > 0) {
      const obj = {};
      DEFAULT_PROVIDERS_ARR.forEach((key) => {
        if (defaultSetting[key] === setting.defaultSetting[key]) {
          obj[key] = defaultSetting[key] || "";
        } else if (defaultSetting[key] !== setting.defaultSetting[key] && setting.defaultSetting[key] === newUser.id) {
          obj[key] = defaultSetting[key] || "";
        } else if (defaultSetting[key] && defaultSetting[key] !== setting.defaultSetting[key]) {
          obj[key] = defaultSetting[key] || "";
        } else {
          obj[key] = setting.defaultSetting[key] || "";
        }
      });
      provDefaultSetting = obj;
    }

    handleSave(objToSave, provDefaultSetting);
    handleClose();
  };

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        animation={true}
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newUser.isNew ? "Create Provider" : "Update Provider"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          {/* {newUser.isNew && (newUser.firstName || newUser.lastName || newUser.npi) && (
            <span className="linkedText cursor-pointer" onClick={() => searchProvider(newUser)}>
              Search Prvider
            </span>
          )} */}
          <div className="row">
            <div className="col-sm-12 createClientsWrapper">
              <div className="d-flex gap-2 mb-3">
                <div>
                  <label className="modalLineHeaders">First Name: </label>
                  <input
                    value={newUser.firstName}
                    className="modalInput w-100"
                    placeholder="Enter First Name"
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        firstName: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <label className="modalLineHeaders">Middle Name: </label>
                  <input
                    value={newUser.middleName}
                    className="modalInput w-100"
                    placeholder="Enter Middle Name"
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        middleName: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <label className="modalLineHeaders">Last Name: </label>
                  <input
                    value={newUser.lastName}
                    className="modalInput w-100"
                    placeholder="Enter Last Name"
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        lastName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Lab Code: </label>
                <input
                  value={newUser.code}
                  className="modalInput w-100"
                  placeholder="Lab Code"
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      code: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">NPI: </label>
                <input
                  type="text"
                  className="modalInput"
                  value={newUser.npi}
                  placeholder="Enter NPI"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => setNewUser({ ...newUser, npi: e.target.value })}
                />
              </div>
            </div>

            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Taxonomy ID: </label>
                <input
                  type="text"
                  className="modalInput"
                  value={newUser.taxid}
                  placeholder="Enter Taxonomy ID"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => setNewUser({ ...newUser, taxid: e.target.value })}
                />
              </div>
            </div>
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Speciality: </label>
                <input
                  type="text"
                  className="modalInput"
                  value={newUser.speciality}
                  placeholder="Enter Speciality"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => setNewUser({ ...newUser, speciality: e.target.value })}
                />
              </div>
            </div>
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Default: </label>
                <Select
                  options={DEFAULT_PROVIDERS}
                  blurInputOnSelect={true}
                  isClearable={true}
                  isMulti
                  menuPlacement="auto"
                  value={
                    defaultSetting && Object.keys(defaultSetting).length > 0
                      ? Object.entries(defaultSetting)
                          .filter(([key, value]) => value === newUser.id || (newUser.isNew && value))
                          .map(([key, val]) => ({ value: key, label: PARSE_PROVIDER[key] }))
                      : null
                  }
                  placeholder="Select Defualt Provider"
                  onChange={(e) => {
                    let obj = {};
                    e.map((m) => {
                      obj[m.value] = newUser.id || true;
                    });

                    setDefaultSetting(obj);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={onHandleSave}
          >
            {newUser.isNew ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
      {recordData.length > 1 && (
        <ProviderSearchRecord
          data={recordData}
          handleSelect={populate}
          handleClose={() => {
            setRecordData([]), setSelectSearchData(null);
          }}
          setSelectSearchData={setSelectSearchData}
          selectSearchdata={selectSearchData}
        />
      )}
    </>
  );
};

export default ProviderModal;
