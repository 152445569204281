import React, { useState, useContext } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import api from "api";
import { Auth } from "aws-amplify";
import { AppContext } from "../context/app-context";
import Filter from "../components/Filter";
import TimeFilter from "../components/TimeFilter";
import UserModal from "components/Users/UserModal";
import UserPasswordModal from "components/Users/UserPasswordModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import TDData from "components/Table/TDData";
import {
  NEW_PROVIDER_OBJECT,
  USER_ROLE_TYPE,
  PAGE_LIMIT,
  HEALTH_AND_SAFTEY,
  USER_ROLE_TITLE,
  CONFIG,
  currencyformatter,
  ClaimDropDownOptions,
  claimSubmitStatus,
  AltaSetting,
  usersNo,
  ERA_VIEW_STATUS,
  TABLE_QUICK_TOOLS,
  PARSE_CLAIM_FILTER_KEY_NAME,
  altaIDS,
} from "../constant";
import { EMP_CLAIM_PERSONALIZE } from "constants/personalization";
import { t } from "../stringConstants";
import {
  formatNumber,
  phoneFormatter,
  parseClaimDate,
  RemoveSpaceAndChar,
  getValidDep,
  formatEmployeeClaim,
  downloadDataAsCSV,
  draggablePersonalizationLocalStorage,
  CapsFirstLetter,
  formatDateMDY,
} from "../utils";
import "../../src/tableStyling.css";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import Icon from "../components/Icon";
import Loader from "components/Loader/Loader";
import CreateClaimResubmissionModal from "components/Modal/Order/CreateClaimResubmissionModal";
import Stats from "components/Stats";
import OrderImportModal from "components/Modal/Order/OrderImportModal";
import moment from "moment";
import MFPagination from "components/Pagination/MFPagination";
import OrderLogsModal from "components/Modal/Order/OrderLogsModal";
import SingleStatsCard from "components/SingleStatsCard";
import { useLocation } from "react-router";
import EraModal from "components/Modal/Order/EraModal";
import ChangeClaimStatusModal from "./EmployeeClaims/ChangeClaimStatusModal";
import NotesModal from "components/Modal/NotesModal";
import { Storage } from "aws-amplify";
import MainTable from "components/Table/MainTable";
import FilterSelect from "components/FilterSelect";

const EmployeeClaim = (props) => {
  const [users, setUsers] = useState([]);
  const [employeeClaims, setEmployeeClaims] = useState([]);
  const [filteredEmployeeClaims, setFilteredEmployeeClaims] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [newUser, setNewUser] = useState(NEW_PROVIDER_OBJECT);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [allSelected, setAllSelected] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [claimReSubmissionModal, setClaimReSubmissionModal] = useState(null);
  const [checkboxes, setCheckboxes] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [openEraModal, setOpenEraModal] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [claimNote, setClaimNote] = useState(null);
  const [changeClaimStatusModal, setChangeClaimStatusModal] = useState(false);
  const location = useLocation();
  const quickTools = [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.edit, TABLE_QUICK_TOOLS.notes];
  const { filterDates } = TimeFilter;

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      const filterTerm = location.state.term;
      const filterTermName = location.state.name;
      const nextTerm = location.state.filterTerm;
      const nextTermName = location.state.filterName;
      if (filterTerm && filterTermName) {
        setFilter({ ...filter, [filterTerm]: filterTermName });
        setTimeout(() => setShowFilter(false), 1);
      }
      if (nextTerm && nextTermName) {
        setFilter({
          ...filter,
          [nextTerm]: nextTermName,
          [filterTerm]: filterTermName,
        });
        setTimeout(() => setShowFilter(false), 1);
      }
    }
  }, [location.state]);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone Number",
    "Address",
    "Apt/Unit#",
    "City",
    "State",
    "Zip Code",
    "Date Of Birth",
    "Gender",
    "Insurance",
    "Insurance #",
    "Claim ID",
    "Claim Amount",
    "Adjusted Amount",
    "Paid Amount",
    "DOS",
    "Status",
    "LTCS ID",
    "B-ID",
    "Processed By",
    "Blank",
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);

  const sortUsers = (users, sortBy) => {
    if (!sortBy) {
      return [...users].sort((a, b) =>
        `${b["pat_name_f"]}${b["pat_name_l"]}` < `${a["pat_name_f"]}${a["pat_name_l"]}`
          ? 1
          : `${a["pat_name_f"]}${a["pat_name_l"]}` < `${b["pat_name_f"]}${b["pat_name_l"]}`
          ? -1
          : 0
      );
    }

    if (sortDescending) {
      return [...users].sort((a, b) => {
        let fieldA = a[sortBy];
        let fieldB = b[sortBy];
        let nameA = `${a["pat_name_f"]}${a["pat_name_l"]}`;
        let nameB = `${b["pat_name_f"]}${b["pat_name_l"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        } else {
          return fieldA < fieldB ? -1 : 1;
        }
      });
    } else {
      return [...users].sort((a, b) => {
        let fieldA = a[sortBy];
        let fieldB = b[sortBy];
        let nameA = `${a["pat_name_f"]}${a["pat_name_l"]}`;
        let nameB = `${b["pat_name_f"]}${b["pat_name_l"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
        } else {
          return fieldB < fieldA ? -1 : 1;
        }
      });
    }
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }

        if (key === "from_date_1") {
          return Object.keys(filters[key]).length > 0
            ? new Date(obj.from_date_1) >= filters[key].startDate?._d &&
                new Date(obj.from_date_1) <= filters[key].endDate?._d
            : obj.from_date_1;
        }
        if (key === "paidAmount") {
          return obj[key] && obj[key].toString().includes(filters[key]);
        }
        if (key === "pat_dob") {
          return obj[key] && formatDateMDY(obj[key]).includes(filters[key]);
        }
        if (key === "notDraft") {
          return obj.status !== claimSubmitStatus.draft;
        }
        if (key === "Blank" && filter[key].length > 0) {
          return filter[key].some((f) => !obj[EMP_CLAIM_PERSONALIZE.find((t) => t.title === f)?.itemKey]);
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = (users) => {
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredEmployeeClaims);

  const getPageNumbers = (users) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(users);

  useEffect(() => {
    getEmployeeClaims();
  }, []);

  const getEmployeeClaims = async () => {
    const models = await api.getEmployeeClaims();

    setEmployeeClaims(formatClaims(models));
    setFilteredEmployeeClaims(sortUsers(filterDates(nestedFilter(formatClaims(models), filter), timeFilter), sortBy));
  };

  const formatClaims = (models) => {
    let claims = [...models];
    claims = claims.map((m) => {
      if (m.paidAmount && m.proc_array.some((s) => !s.isClaimProcess)) {
        return { ...m, partialAmount: true, paid_ins_processed: m.paid_insurance?.payer_name || "" };
      }
      return {
        ...m,
        partialAmount: false,
        submissionDate: m.reSubmissionDate || m.submissionDate || m.createdAt,
        paid_ins_processed: m.paid_insurance?.payer_name || "",
      };
    });
    return claims;
  };

  useEffect(() => {
    if (filteredEmployeeClaims.length > 0)
      setFilteredEmployeeClaims(
        sortUsers(filterDates(nestedFilter(filteredEmployeeClaims, filter), timeFilter), sortBy)
      );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (employeeClaims.length > 0) {
      setFilteredEmployeeClaims(sortUsers(filterDates(nestedFilter(employeeClaims, filter), timeFilter), sortBy));
      setCurrentPage(1);
    }
  }, [filter, employeeClaims, timeFilter]);

  useEffect(() => {
    setPersonalize(draggablePersonalizationLocalStorage.get(appContext?.user, "employeeClaim", EMP_CLAIM_PERSONALIZE));
  }, [appContext?.company]);

  if (filteredEmployeeClaims.length > 0) {
    pageNumbers = getPageNumbers(filteredEmployeeClaims);
  }

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
    handleChangePage(1);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "employeeClaim");
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(appContext.user.phone_number, personalisationData);
  };

  // const getDuplicateMember = async () => {
  //   const emps = appContext.employees.filter(
  //     (f, i, arr) =>
  //       arr.findIndex((m) => m.phoneNumber === f.phoneNumber && f.dob === m.dob && f.medicalNo === m.medicalNo) !== i
  //   );
  //   const duplicateData = appContext.employees.filter(
  //     (f, i) =>
  //       emps.findIndex((m) => m.phoneNumber === f.phoneNumber && f.dob === m.dob && f.medicalNo === m.medicalNo) !== -1
  //   );
  //   const dataList = duplicateData.map((m) => {
  //     const claims = employeeClaims.filter((f) => f.pcn === m.schrID).length;
  //     return { ...m, claims };
  //   });

  //   console.log("duplicateData", dataList);
  //   await downloadDataAsCSV(dataList, "patientDuplicate.csv");
  // };

  const getDuplicateData = async () => {
    const emps = filteredEmployeeClaims.filter(
      (f, i, arr) =>
        arr.findIndex(
          (m) => m.proc_array[0].remote_chgid_1 === f.proc_array[0].remote_chgid_1 && f.from_date_1 === m.from_date_1
        ) !== i
    );
    const duplicateData = filteredEmployeeClaims.filter(
      (f, i) =>
        emps.findIndex(
          (m) => m.proc_array[0].remote_chgid_1 === f.proc_array[0].remote_chgid_1 && f.from_date_1 === m.from_date_1
        ) !== -1
    );
    console.log("duplicateData", duplicateData);
    await downloadDataAsCSV(duplicateData, "SevantDuplicateEmployees.csv");
  };

  const handleImport = async (empData, subAgentID, orderMonth, shipmentDate, claimStatus) => {
    if (empData.length === 0) {
      appContext.showErrorMessage(t("emptyFile"));
      setOpenImportModal(false);
      return;
    }

    if (!orderMonth) {
      appContext.showErrorMessage("Please select Claim date");
      return;
    }
    if (!claimStatus) {
      appContext.showErrorMessage("Please Select Claim Status");
      return;
    }

    const subAgent = await api.getSubAgentById(subAgentID || "886a8f16-7d65-4a18-8f8e-51c14e71b8ed");
    const location = await api.getLocationById(subAgent.locationID);
    const client = await appContext.getClientById(subAgent.clientID);
    setOpenImportModal(false);
    setLoading(true);
    const ttlLength = empData.length;
    let arr = [];
    let updateArr = [];
    let fileData = [];
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data };
      if (!emp.pat_name_f && !emp.pat_dob) continue;
      emp.clientID = client.id;
      emp.clientName = client.name;
      emp.locationID = location.id;
      emp.locationName = location.name;
      emp.subAgentID = subAgent.id;
      emp.subAgentName = subAgent.name;
      emp.payerid = emp.payerid.padStart(5, 0);
      emp.pat_phone = phoneFormatter(emp.pat_phone || "");
      emp.bill_phone = phoneFormatter(emp.bill_phone || "");
      emp.ins_phone = phoneFormatter(emp.ins_phone || "");
      emp.other_payer_phone = phoneFormatter(emp.other_payer_phone || "");
      emp.pat_dob = parseClaimDate(emp.pat_dob || "");
      emp.ins_dob = parseClaimDate(emp.ins_dob || "");
      emp.other_ins_dob = parseClaimDate(emp.other_ins_dob || "");
      emp.createdAt = moment(`${orderMonth} 20:00:00`).toISOString();
      emp.units_1 = parseFloat(emp.units_1);
      emp.status = claimStatus;
      emp.ins_number = RemoveSpaceAndChar(emp.ins_number);
      emp.orderId = emp.remote_claimid.slice(7);
      emp.pat_name_f = getValidDep(emp.pat_name_f || "");
      emp.pat_name_l = getValidDep(emp.pat_name_l || "");
      emp.pat_name_m = getValidDep(emp.pat_name_m || "");
      emp.ins_name_f = getValidDep(emp.ins_name_f || "");
      emp.ins_name_l = getValidDep(emp.ins_name_l || "");
      emp.ins_name_m = getValidDep(emp.ins_name_m || "");

      if (claimStatus !== "submitted") {
        const findClaim = employeeClaims.find(
          (f) => f.remote_claimid.trim().toLowerCase() === emp.remote_claimid.trim().toLowerCase()
        );
        if (findClaim) {
          await api.updateEmployeeClaimStatus(findClaim, claimStatus);
          updateArr.push(emp);
        }
        continue;
      }
      let proc_array = [];
      const procArr = Object.keys(emp).filter((f) => f.includes("proc_code"));
      procArr.map((key, index) => {
        const i = index + 1;
        const splitDiagRef = emp[`diag_ref_${i}`].split(",");
        const obj = {
          proc_code: emp[`proc_code_${i}`],
          from_date: parseClaimDate(emp[`from_date_${i}`]),
          diag_ref_1: splitDiagRef[0],
          diag_ref_2: splitDiagRef[1],
          diag_ref_3: splitDiagRef[2],
          diag_ref_4: splitDiagRef[3],
          charge: emp[`charge_${i}`],
          units: emp[`units_${i}`],
          place_of_service: emp[`place_of_service_${i}`],
          mod1: emp[`mod1_${i}`],
          mod2: emp[`mod2_${i}`],
          mod3: emp[`mod3_${i}`],
          mod4: emp[`mod4_${i}`],
          [`remote_chgid_${i}`]: emp[`remote_chgid_${i}`],
        };
        proc_array.push(obj);
      });
      emp.proc_array = proc_array;
      const {
        prov_name_f,
        prov_name_l,
        prov_name_m,
        prov_npi,
        prov_taxid,
        prov_taxid_type,
        prov_taxonomy,
        ord_prov_name_f,
        ord_prov_name_l,
        ord_prov_name_m,
        ord_prov_npi,
        ref_name_f,
        ref_name_l,
        ref_name_m,
        ref_npi,
      } = emp;
      const renderProviderID = appContext.providers.find(
        (f) => f.lastName.trim().toLowerCase() === prov_name_l.trim().toLowerCase()
      );
      const referingProviderID = appContext.providers.find(
        (f) => f.lastName.trim().toLowerCase() === ref_name_l.trim().toLowerCase()
      );
      const orderingProviderID = appContext.providers.find(
        (f) => f.lastName.trim().toLowerCase() === ord_prov_name_l.trim().toLowerCase()
      );
      emp.provider = {
        prov_name_f,
        prov_name_l,
        prov_name_m,
        prov_id: renderProviderID?.id || "",
        prov_npi,
        prov_taxid,
        prov_taxid_type,
        prov_taxonomy,
        ord_prov_id: orderingProviderID?.id || "",
        ord_prov_name_f,
        ord_prov_name_l,
        ord_prov_name_m,
        ord_prov_npi,
        ref_id: referingProviderID?.id || "",
        ref_name_f,
        ref_name_l,
        ref_name_m,
        ref_npi,
      };
      emp.employeeID = appContext.employees.find((f) => f.schrID === emp.pcn.trim())?.id ?? "";
      emp.updatedByName = appContext.user.name;
      emp.updatedBy = appContext.user.sub;

      const findClaim = employeeClaims.find(
        (f) => f.remote_claimid.trim().toLowerCase() === emp.remote_claimid.trim().toLowerCase()
      );
      if (findClaim) {
        console.log("findClaim", findClaim);
        continue;
      }

      try {
        console.log("emp", emp);
        const res = await api.createClaim(emp);
        if (res.data) {
          fileData.push(res.data);
        }
        if (res) arr.push(emp);
      } catch (err) {
        console.log("Error:-", err);
      }
    }
    console.log("FileData", fileData);
    const fileName = `claim/audit_logs_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
    await api.saveFileOnBucket(fileData, fileName);
    setLoading(false);
    getEmployeeClaims();
    if (updateArr.length > 0) appContext.showSuccessMessage(`${updateArr.length} Claim(s) Updated Successfully!`);
    if (arr.length > 0) appContext.showSuccessMessage(`${arr.length} Claim(s) Imported Successfully!`);
    console.log("ArrData", arr);
  };

  const handleRejcetedImport = async (empData, subAgentID, orderMonth, shipmentDate, claimStatus) => {
    if (empData.length === 0) {
      appContext.showErrorMessage(t("emptyFile"));
      setOpenImportModal(false);
      return;
    }

    if (!orderMonth) {
      appContext.showErrorMessage("Please select Claim date");
      return;
    }
    if (!claimStatus) {
      appContext.showErrorMessage("Please Select Claim Status");
      return;
    }
    setOpenImportModal(false);
    setLoading(true);
    const ttlLength = empData.length;
    let arr = [];
    let updateArr = [];

    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data };

      const findClaim = employeeClaims.find(
        (f) => f.remote_claimid.trim().toLowerCase() === emp.remote_claimid.trim().toLowerCase()
      );
      if (findClaim) {
        const logStatus = emp.messages.includes("ERA DENIAL") ? "denied" : "rejected";
        // await api.updateEmployeeClaimStatus(findClaim, logStatus,emp.messages);
        updateArr.push({
          ...findClaim,
          message: emp.messages,
          status: logStatus,
          local_status_time: emp.local_status_time,
        });
      }
    }

    // const findClaim = employeeClaims.map((f)=>{
    //   return {...f , message: 'Submit for Bill', status: 'submitted', local_status_time: f.createdAt }
    // });

    const fileName = `claim/audit_logs_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
    await api.saveFileOnBucket(updateArr, fileName);

    setLoading(false);
    getEmployeeClaims();
    if (updateArr.length > 0) appContext.showSuccessMessage(`${updateArr.length} Claim(s) Updated Successfully!`);
    if (arr.length > 0) appContext.showSuccessMessage(`${arr.length} Claim(s) Imported Successfully!`);
    console.log("ArrData", arr);
  };

  const handleDownloadExportCsv = async () => {
    const claimList = filteredEmployeeClaims.map((claim) => {
      return formatEmployeeClaim(claim, AltaSetting);
    });
    await downloadDataAsCSV(claimList, "claim_listing.csv");
  };

  const getTotalClaimSubmit = (list, val) => {
    switch (val) {
      case "totalClaimsSubmitted":
        return formatNumber(
          list
            .filter((f) => f.status !== "draft")
            .reduce((val, obj) => {
              return 1 + val;
            }, 0)
        );
      case "totalClaimsRejected":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "rejected" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsDraft":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "draft" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsDenied":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "denied" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsApproved":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "approved" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsPaid":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "paid" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsPartail":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "partial" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsCancel":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === "cancel" ? 1 : 0) + val;
          }, 0)
        );
      case "totalDraftAmount":
        return list.reduce((val, obj) => {
          return (obj.status === "draft" ? parseFloat(obj.total_charge) || 0 : 0) + val;
        }, 0);
      case "totalChargeAmount":
        return list.reduce((val, obj) => {
          return (parseFloat(obj.total_charge) || 0) + val;
        }, 0);
      case "totalAmountRejected":
        return list.reduce((val, obj) => {
          return (obj.status === "rejected" ? parseFloat(obj.total_charge) || 0 : 0) + val;
        }, 0);
      case "totalReceivedAmount":
        return list.reduce((val, obj) => {
          return (obj.status === "approved" ? parseFloat(obj.total_charge) || 0 : 0) + val;
        }, 0);
      case "totalPaidAmount":
        return list.reduce((val, obj) => {
          return (obj.status === "paid" ? parseFloat(obj.paidAmount) || 0 : 0) + val;
        }, 0);
      case "totalPartialAmount":
        return list.reduce((val, obj) => {
          return (obj.status === "partial" ? parseFloat(obj.paidAmount) || 0 : 0) + val;
        }, 0);
      case "totalDeniedAmount":
        return list.reduce((val, obj) => {
          return (obj.status === "denied" ? parseFloat(obj.total_charge) || 0 : 0) + val;
        }, 0);
      case "totalCancelAmount":
        return list.reduce((val, obj) => {
          return (obj.status === "cancel" ? parseFloat(obj.total_charge) || 0 : 0) + val;
        }, 0);

      default:
        return 0;
    }
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredEmployeeClaims.map((t) => t.id) : []);
  };

  const handleConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);

    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    const empsFilter = filteredEmployeeClaims.filter(
      (f) => checkboxes.includes(f.id) && f.status === claimSubmitStatus.draft
    );
    let ttl = empsFilter.length;

    if (
      ttl === 0 &&
      userToDelete.status !== claimSubmitStatus.draft &&
      appContext.user?.phone_number !== usersNo.ibrahim
    ) {
      appContext.showErrorMessage("Only draft claims would be able to delete");
      setCheckboxes([]);
      return;
    }
    try {
      setLoading(true);
      if (userToDelete || ttl > 0) {
        if (userToDelete) {
          await api.deleteEmployeeClaim(userToDelete.id);
        } else if (ttl > 0) {
          for (let i = 0; i < ttl; i++) {
            await api.deleteEmployeeClaim(empsFilter[i].id);
          }
        }
      }
      setUserToDelete(null);
      setCheckboxes([]);
      appContext.showSuccessMessage("Claim Deleted Successfully");
      getEmployeeClaims();
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  const tdFormat = (item, row) => {
    if (item === "submissionDate") return formatDateMDY(row.submissionDate);
    return row[item];
  };
  const tdFormatTitle = (item, row) => {
    if (item === "submissionDate") return formatDateMDY(row.submissionDate);
    return row[item];
  };

  const tdStatus = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
          color: item.colorObj[row[item.itemKey]],
          cursor: "pointer",
        }}
        title={`${row.message || ""}${row.crossover_carrier ? `\n ${row.crossover_carrier}` : ""}`}
        onClick={() => handleCellClick(item.itemKey, row)}
      >
        {CapsFirstLetter(row.status)}
      </td>
    );
  };
  const renderTd = (item, row) => {
    if (item.itemKey === "status") return tdStatus(item, row);
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormatTitle(item.itemKey, row)}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCreateClaim = async () => {
    if (checkboxes.length === 1) {
      let res = filteredEmployeeClaims.filter((f) => checkboxes.indexOf(f.id) !== -1);
      setClaimReSubmissionModal(res.map((m) => ({ employeeId: m.employeeID })));
    } else {
      setClaimReSubmissionModal([]);
    }
  };

  const handleOpenEraFile = async (user) => {
    const eraid = user.eraIds.split(",");
    let path = await api.getPatientERA({ ...user, eraid: eraid[0] });
    console.log("path", path);
    if (!path) {
      window.open(`https://medflow-images.s3.eu-west-1.amazonaws.com/era_view.pdf`);
      return;
    }
    try {
      const res = await Storage.get(path, {
        bucket: CONFIG.eligibilityBucket,
      });

      window.open(`${res}`);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const handleAcknowledgeBy = async (data) => {
    await api.isAcknowledgeClaim(data, appContext.user);
    getEmployeeClaims();
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.edit:
        setClaimReSubmissionModal([row]);
        if (
          (row.status === claimSubmitStatus.denied || row.status === claimSubmitStatus.rejected) &&
          !row.acknowledgeBy
        ) {
          handleAcknowledgeBy(row);
        }
        break;

      case TABLE_QUICK_TOOLS.notes:
        !appContext.isReadOnly() && setClaimNote(row);
        break;

      case "status":
        row.status === ERA_VIEW_STATUS[row.status] && setOpenEraModal(row);
        break;

      case "eraIds":
        row.eraIds && handleOpenEraFile(row);
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  {t("employeeClaim")} (
                  {filteredEmployeeClaims ? formatNumber(filteredEmployeeClaims.length) : employeeClaims.length})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={async () => {
                      if (filteredEmployeeClaims && filteredEmployeeClaims.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                      // const fileName = `claim/test_logs_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
                      // await api.saveFileOnBucket(
                      //   employeeClaims.filter((f) => f.status === "rejected" || f.status === "denied"),
                      //   fileName
                      // );
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  <FilterSelect setTimeFilter={setTimeFilter} />
                  <Icon
                    handleClick={() => setOpenDeleteConfirmation(true)}
                    label="Delete"
                    title="Delete"
                    disabled={checkboxes.length === 0}
                    iconType={"binIcon"}
                  />

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />

                  <Icon
                    handleClick={handleDownloadExportCsv}
                    title={"Export Claim"}
                    label={"Export"}
                    iconType={"downloadIcon"}
                  />

                  <Icon
                    title={t("createClaim")}
                    label={t("createClaim")}
                    iconType={"acknowledgedPositiveIcon"}
                    handleClick={() => {
                      handleCreateClaim();
                    }}
                  />
                  {/* <Icon
                    handleClick={() => {
                      setOpenImportModal(true);
                    }}
                    title={t("loadOrderData")}
                    label={"Import"}
                    iconType={"importIcon"}
                  /> */}
                  {appContext.user.phone_number === usersNo.ibrahim && (
                    <Icon
                      title={t("updateClaimStatus")}
                      label={t("updateClaimStatus")}
                      iconType={"acknowledgedPositiveIcon"}
                      disabled={checkboxes.length === 0}
                      handleClick={() => setChangeClaimStatusModal(true)}
                    />
                  )}
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    isUsersTable={true}
                    employeeClaim={true}
                  />
                )}

                <div className="dashboard-wrapper">
                  <div className="d-flex w-100">
                    <SingleStatsCard
                      title={"Draft"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsDraft")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalDraftAmount"))}
                      filterKey="status"
                      value="draft"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Submitted"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsSubmitted")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalChargeAmount"))}
                      filterKey="status"
                      value="submitted"
                      handelClick={(obj) => setFilter({ notDraft: "notDraft" })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Rejected"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsRejected")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalAmountRejected"))}
                      filterKey="status"
                      value="rejected"
                      handelClick={(obj) =>
                        setFilter({
                          ...obj,
                        })
                      }
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Approved"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsApproved")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalReceivedAmount"))}
                      filterKey="status"
                      value="approved"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Partially Processed"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsPartail")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalPartialAmount"))}
                      filterKey="status"
                      value="partial"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Paid"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsPaid")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalPaidAmount"))}
                      filterKey="status"
                      value="paid"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Denied"}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalDeniedAmount"))}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsDenied")}
                      filterKey="status"
                      value="denied"
                      handelClick={(obj) =>
                        setFilter({
                          ...obj,
                        })
                      }
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Cancelled"}
                      totalCounts={getTotalClaimSubmit(employeeClaims, "totalClaimsCancel")}
                      amount={currencyformatter.format(getTotalClaimSubmit(employeeClaims, "totalCancelAmount"))}
                      filterKey="status"
                      value="cancel"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                  </div>
                </div>
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers.length}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  columns={personalize}
                  rows={usersToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={"employeeClaim"}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={155}
                  tools={quickTools}
                  dropDownOptions={ClaimDropDownOptions}
                  handleDropDownClick={(type, row) => {
                    if (type === "logs") {
                      setOpenOrderDetail(true);
                      setSelectedOrder(row);
                    }
                    if (type === "delete") {
                      setOpenDeleteConfirmation(true);
                      setUserToDelete(row);
                    }
                  }}
                  selectedRows={checkboxes}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {claimReSubmissionModal && (
        <CreateClaimResubmissionModal
          // orders={filteredOrders.filter((f) => checkboxes.indexOf(f.id) !== -1)}
          user={claimReSubmissionModal}
          handleClose={(val) => {
            setClaimReSubmissionModal(null);
            setCheckboxes([]);
            if (val) {
              getEmployeeClaims();
            }
          }}
          fileDir="claim"
        />
      )}
      {changeClaimStatusModal && (
        <ChangeClaimStatusModal
          selectedClaimIds={checkboxes || []}
          cssClass={"seperateModal"}
          handleClose={(val) => {
            if (val) getEmployeeClaims();
            setChangeClaimStatusModal(false);
            setCheckboxes([]);
          }}
        />
      )}
      {openEraModal && <EraModal user={openEraModal} handleClose={() => setOpenEraModal(null)} />}
      {openImportModal && (
        <OrderImportModal
          handleClose={() => setOpenImportModal(false)}
          handleImport={handleImport}
          appContext={appContext}
          claim
          title="Import Claim Data"
        />
      )}

      {openOrderDetail && (
        <OrderLogsModal
          order={selectedOrder}
          show={openOrderDetail}
          appContext={appContext}
          handleClose={() => {
            setOpenOrderDetail(false);
            setSelectedOrder("");
          }}
          claim
        />
      )}

      {claimNote && (
        <NotesModal
          handleClose={(obj) => {
            if (obj) {
              obj.note
                ? appContext.showSuccessMessage("add Notes Successfully")
                : appContext.showSuccessMessage("Remove Notes Successfully");
              const findVal = employeeClaims.find((f) => f.id === obj.id);
              if (findVal) {
                Object.assign(findVal, { note: obj.note });
                const index = employeeClaims.findIndex((f) => f.id === findVal.id);
                if (index !== -1) {
                  const data = [...employeeClaims];
                  data.splice(index, 1, findVal);
                  setEmployeeClaims(data);
                  setFilteredEmployeeClaims(sortUsers(filterDates(nestedFilter(data, filter), timeFilter), sortBy));
                }
              }
            }
            setClaimNote(null);
          }}
          data={claimNote}
          claim={true}
          user={appContext.user}
        />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete Claim"
          message="Are you sure, you want to remove claim?"
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default EmployeeClaim;
