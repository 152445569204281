import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Card, Table, Row, Col } from "react-bootstrap";

const EligibilityCheckListViewModal = (props) => {
  const { handleClose, lists, setEligibilityDetailsData } = props;

  const TableRow = ({ item }) => {
    const { emp, res } = item;
    return (
      <tr>
        <td></td>
        <td>{emp.firstName}</td>
        <td>{emp.lastName}</td>
        <td>{emp.phoneNumber}</td>
        <td>{res ? "Pass" : "Fail"}</td>
        <td className="linkedText cursor-pointer" onClick={() => setEligibilityDetailsData(res)}>
          View
        </td>
      </tr>
    );
  };

  return (
    <Modal show onHide={handleClose} animation={true} size={"lg"} scrollable>
      <Modal.Header closeButton>
        <Modal.Title className="my-0">Eligibility View</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="strpied-tabled-with-hover">
          <Card.Body className="table-full-width desktop-noScroll">
            <div
              className={
                lists.length > 0
                  ? "table-responsive pendingReleaseTable"
                  : "table-responsive pendingReleaseTable overFlow-y-hidden"
              }
            >
              <Table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Eligibility</th>
                  </tr>
                </thead>
                <tbody>
                  {lists.map((elig, index) => (
                    <TableRow key={index} item={elig} />
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EligibilityCheckListViewModal;
