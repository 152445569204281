import React, { useState } from "react";
import { Button, Modal, Card, Table, Row, Col, Collapse } from "react-bootstrap";
import { formatDateMDY, formatDateMDYTime, toTitleCase } from "utils";
import api from "api";
import { Audit_Types, Zone_Audit_Types } from "constant";
import { Audit_Types_Show } from "constant";
import { Audit_Parse } from "constant";
import { objTypes } from "constant";
import { AdditionalKeys } from "constant";
import { fieldLabel } from "constant";
import { insuranceProviderFormatter } from "utils";
import { applyCustomOrder } from "constant";
const AuditTrailEntries = (props) => {
  const { item, auditTrails, user, handleOpenDetailModal } = props;
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);

  const handleExpand = () => {
    const sortList = auditTrails.sort((a, b) => (b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0));
    let logs = sortList.filter((f) => Audit_Types[f.eventType] === item);
    setItems(logs);
    setShow(!show);
  };
  const TableCollapse = ({ objKeys, newRecord, oldRecord }) => {
    return (
      <>
        {fieldLabel[objKeys] ? (
          <tr className="expended-row">
            <td style={{ textAlign: "center" }}>{fieldLabel[objKeys]}</td>
            <td style={{ textAlign: "center" }}>
              {objKeys === "insuranceCompany"
                ? insuranceProviderFormatter(oldRecord[objKeys][objTypes[objKeys] || "S"])
                : objKeys === "eligibilityStatus"
                ? oldRecord[objKeys] && oldRecord[objKeys]["M"]["message"][objTypes[objKeys] || "S"]
                : oldRecord[objKeys] && oldRecord[objKeys][objTypes[objKeys] || "S"]}
            </td>
            <td style={{ textAlign: "center" }}>
              {objKeys === "insuranceCompany"
                ? insuranceProviderFormatter(newRecord[objKeys][objTypes[objKeys] || "S"])
                : objKeys === "eligibilityStatus"
                ? newRecord[objKeys] && newRecord[objKeys]["M"]["message"][objTypes[objKeys] || "S"]
                : newRecord[objKeys] && newRecord[objKeys][objTypes[objKeys] || "S"]}
            </td>
          </tr>
        ) : (
          <></>
        )}
      </>
    );
  };

  const TableRow = ({ log, index }) => {
    const [open, setOpen] = useState(null);
    return (
      <>
        <tr>
          <td>
            {log.changeObject && (
              <i
                onClick={() => setOpen(!open ? log.changeObject : null)}
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.color = "#8B0000";
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                className={open ? "fas fa-minus" : "fas fa-plus"}
              />
            )}
          </td>
          <td>{index + 1}</td>
          <td>{formatDateMDY(log.createdAt)}</td>
          <td
            style={{
              textAlign: "center",
              cursor: log.eventType === "Eligibility" ? "pointer" : "",
            }}
            onClick={() => log.eventType === "Eligibility" && handleOpenDetailModal(log)}
          >
            {Audit_Parse[log.eventType]}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {log.result || log.action || log.reason || (log.eventData && (Audit_Parse[log.eventData] || log.eventData))}
          </td>
          <td style={{ textAlign: "center" }}>{log.userName || "System"}</td>
          {/* <td
            onClick={async () => {
              await api.deleteLogRecord(log.id);
            }}
          >
            <i className="fas fa-trash cursor-pointer" />
          </td> */}
        </tr>
        {open && (
          <tr>
            <td colSpan={"12"} className="expended-table-child">
              <Card className="striped-table-card">
                <Card.Body>
                  <div className="form-group-wrapper d-block">
                    <div className="table-responsive overFlow-y-hidden">
                      <Table className="table expandedTableChild">
                        <thead>
                          <tr>
                            <th className="fw-bold" style={{ textAlign: "center" }}>
                              Field
                            </th>
                            <th style={{ textAlign: "center" }}>Old Value</th>
                            <th style={{ textAlign: "center" }}>New Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {open.newRecord &&
                            open.oldRecord &&
                            applyCustomOrder(Object.keys(open.newRecord), Object.keys(fieldLabel))
                              .filter((f) => !AdditionalKeys.includes(f))
                              .map((m, i) => {
                                return (
                                  <TableCollapse
                                    key={i}
                                    objKeys={m}
                                    oldRecord={open.oldRecord}
                                    newRecord={open.newRecord}
                                  />
                                );
                              })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </td>
          </tr>
        )}
      </>
    );
  };
  return (
    <>
      <tr
        onClick={handleExpand}
        onMouseOver={(e) => {
          e.target.style.cursor = "pointer";
        }}
      >
        <td>
          <i
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.color = "#8B0000";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            className={show ? "fas fa-minus" : "fas fa-plus"}
          />
        </td>
        <td>{item}</td>
      </tr>
      {items.length > 0 && show && (
        <tr className="subTableRow">
          <td colSpan={"12"} className="expended-table-wrapper">
            <Card className="striped-table-card">
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table expandedTable">
                      <thead>
                        <tr>
                          <th />
                          <th style={{ width: "10%" }}>Sr#</th>
                          <th>Date</th>
                          <th className="border-0 centered">Event</th>
                          <th className="border-0 centered w-25">Action</th>
                          {/* <th className="border-0 centered">Detail</th> */}
                          <th className="border-0 centered">User</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.length > 0 &&
                          items.map((item, i) => {
                            return <TableRow key={i} index={i} log={item} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </td>
        </tr>
      )}
    </>
  );
};

export default AuditTrailEntries;
